<mat-tab-group>

  <mat-tab label="GENERAL">
    <form [formGroup]="assessmentGeneralForm">
      <mat-checkbox formControlName="enabled" (change)="toggleAssessment($event.checked)" color="primary">Enable
        assessment</mat-checkbox>
      <mat-form-field class="wide-text-form">
        <mat-label>Assessment title</mat-label>
        <input matInput formControlName="title">
      </mat-form-field>
      <mat-form-field class="wide-text-form">
        <mat-label>Assessment description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
      <div class="score-input-wrapper">
        <mat-form-field appearance="outline" floatLabel="always" class="score-input">
          <mat-label>Min score</mat-label>
          <mat-icon matPrefix fontSet="fa" fontIcon="fa-award"></mat-icon>
          <input matInput type="number" placeholder="Min" formControlName="minScore">
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always" class="score-input">
          <mat-label>Max score</mat-label>
          <mat-icon matPrefix fontSet="fa" fontIcon="fa-award"></mat-icon>
          <input matInput type="number" placeholder="Max" formControlName="maxScore">
        </mat-form-field>
      </div>
    </form>
  </mat-tab>

  <mat-tab label="CRITERIA" [disabled]="!this.assessmentGeneralForm.controls.enabled.value">
    <ng-template matTabContent>
      <ng-container *ngFor="let criteria of assessment.criterias; index as i; trackBy:trackByIndex">
        <editable-drawer [content]="criteria" [index]="i" [canDelete]="canDeleteCriteria()" [showIndex]="true"
          (changed)="criteriaChanged($event)" (deleted)="removeCriteria($event)"></editable-drawer>
      </ng-container>
      <div class="add-criteria">
        <mat-form-field class="add-criteria-title">
          <mat-label>Criteria title</mat-label>
          <input matInput [(ngModel)]="newCriteriaTitle" [disabled]="!canAddCriteria()">
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!canAddCriteria()" (click)="addCriteria()">
          <mat-icon matPrefix fontSet="fa" fontIcon="fa-plus-circle"></mat-icon>
          ADD CRITERIA
        </button>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="PREVIEW" [disabled]="!this.assessmentGeneralForm.controls.enabled.value">
    <ng-template matTabContent>
      <button mat-raised-button color="primary" (click)="openReportPage()"> OPEN REPORT PAGE</button>
    </ng-template>
  </mat-tab>
</mat-tab-group>