import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { TextFieldModule } from '@angular/cdk/text-field';
import { AssessmentEditDialogComponent } from '@app/main/apps/forms/assessment/assessment-edit-dialog/assessment-edit-dialog.component';
import { AssessmentCriteriaFormComponent } from './assessment-criteria-form/assessment-criteria-form.component';

@NgModule({
    declarations: [
        AssessmentEditDialogComponent,
        AssessmentCriteriaFormComponent
    ],
    imports: [
        SharedModule,
        TextFieldModule
    ],
    exports: [
        AssessmentEditDialogComponent
    ]
})

export class AssessmentEditDialogModule { }
