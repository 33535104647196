import { Injectable, ElementRef } from '@angular/core';
import { Observable, from, of, Subject } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Asset } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import {
  switchMap,
  map,
  catchError
} from 'rxjs/operators';

@Injectable()
export class UploadService {
  private CHUNK_SIZE = 10e6; //10MB
  private MAX_HEIGHT = 4320;
  private MAX_WIDTH = this.MAX_HEIGHT * 2; //max for our webp lib is 16383 so we still have some room to grow
  private THUMBNAIL = { fileType: 'image/jpeg', name: 'thumbnail.jpeg' };
  private gCloudServer = environment.contentBucketPrefix;
  private localMediaElm: HTMLImageElement | HTMLVideoElement;
  progress: Subject<{ name: string; progress: number }> = new Subject();

  constructor(
    private _http: HttpClient,
    private _courseService: CoursesAPIService
  ) { }

  public loadMediaInLocal(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = URL.createObjectURL(file);
      if (file.type.indexOf('image') !== -1) {
        this.localMediaElm = document.createElement('img');
        this.localMediaElm.onload = (event) => {
          return resolve(this.localMediaElm);
        };
      } else if (file.type.indexOf('video') !== -1) {
        this.localMediaElm = document.createElement('video');
        this.localMediaElm.currentTime = 2;
        this.localMediaElm.addEventListener('seeked', function me(): void {
          this.removeEventListener('seeked', me, false);
          return resolve(this);
        });
      } else {
        return reject('loadMediaInLocal:: Unexpected media load');
      }
      this.localMediaElm.src = url;
    });
  };

  public unloadLocalMedia(): void {
    if (this.localMediaElm !== undefined) {
      URL.revokeObjectURL(this.localMediaElm.src);
      this.localMediaElm.remove();
      this.localMediaElm = undefined;
    }
  }

  public async getMediaMetadata(file: File): Promise<{ height: number, width: number, duration?: number }> {
    let metadata: { height: number, width: number, duration?: number };
    if (this.localMediaElm === undefined) {
      await this.loadMediaInLocal(file);
    }
    if (this.localMediaElm instanceof HTMLImageElement) {
      metadata = { height: this.localMediaElm.height, width: this.localMediaElm.width };
    } else if (this.localMediaElm instanceof HTMLVideoElement) {
      metadata = { height: this.localMediaElm.videoHeight, width: this.localMediaElm.videoWidth, duration: this.localMediaElm.duration };
    } else {
      this.unloadLocalMedia();
      throw new Error(`getMediaMetadata:: Unexpected file type: ${file.type}`);
    }
    return metadata;
  }

  public validateUpload(metadata: { height: number, width: number, duration?: number }): boolean {
    if (metadata.height > this.MAX_HEIGHT || metadata.width > this.MAX_WIDTH) {
      return false;
    } else {
      return true;
    }
  }

  public uploadFile(file: File, filepath: string) {
    if (file.size > this.CHUNK_SIZE) {
      return this.resumableUpload(file, filepath);
    } else {
      return this.simpleUpload(file, filepath);
    }
  }
  public createAndUploadAsset(
    asset: Asset,
    file: File,
    course_id: string = null
  ): Observable<Asset> {
    let newAsset: Asset;
    return this._courseService.createMedia(asset, course_id).pipe(
      switchMap(a => {
        const filename = `/content/${a.id}/source.${a.name.split('.').pop()}`;
        newAsset = a;
        return this.uploadFile(file, filename);
      }),
      switchMap(newName => {
        return this._courseService.partialUpdateMedia(newAsset.id, {
          path: this.gCloudServer + newName
        });
      })
    );
  }

  public uploadAndAddThumbnailToAsset(
    asset: Asset,
    file: File
  ): Observable<Asset> {
    const thumbName = '/content/' + asset.id + '/' + this.THUMBNAIL.name;
    return this.uploadFile(file, thumbName).pipe(
      switchMap(() => {
        return this._courseService.partialUpdateMedia(asset.id, {
          thumbnail: this.gCloudServer + thumbName
        });
      })
    );
  }

  public async redrawImage(file: File, canvasElement: HTMLCanvasElement): Promise<any> {
    if (this.localMediaElm === undefined) {
      await this.loadMediaInLocal(file);
    }

    const wSrc = this.localMediaElm.width;
    const hSrc = this.localMediaElm.height;
    if (this.localMediaElm instanceof HTMLVideoElement) {
      return file;
    }

    canvasElement.width = wSrc;
    canvasElement.height = hSrc;
    canvasElement.getContext('2d').drawImage(this.localMediaElm, 0, 0, wSrc, hSrc);
    this.unloadLocalMedia();
    return new Promise(resolve => { canvasElement.toBlob(resolve, file.type); });
  }

  private async resizeAndDrawThumbnail(file: File, canvasElement: HTMLCanvasElement): Promise<any> {
    if (this.localMediaElm === undefined) {
      await this.loadMediaInLocal(file);
    }
    let wSrc = this.localMediaElm.width;
    let hSrc = this.localMediaElm.height;
    if (this.localMediaElm instanceof HTMLVideoElement) {
      wSrc = this.localMediaElm.videoWidth;
      hSrc = this.localMediaElm.videoHeight;
    }

    const wDest = 168;
    const hDest = 94;
    const ratioDest = wDest / hDest;
    const ratioSrc = wSrc / hSrc;

    let wFinal, hFinal, wOffSet, hOffset;
    if (ratioDest < ratioSrc) {
      wFinal = wSrc;
      hFinal = wSrc / ratioDest;
      wOffSet = 0;
      hOffset = (hSrc - hFinal) / 2;
    } else {
      wFinal = hSrc * ratioDest;
      hFinal = hSrc;
      wOffSet = (wSrc - wFinal) / 2;
      hOffset = 0;
    }
    canvasElement.width = wDest;
    canvasElement.height = hDest;
    canvasElement.getContext('2d').drawImage(this.localMediaElm, wOffSet, hOffset, wFinal, hFinal, 0, 0, wDest, hDest);
    this.unloadLocalMedia();
    return new Promise(resolve => { canvasElement.toBlob(resolve, this.THUMBNAIL.fileType); });
  }

  public async generateThumbnail(
    file: File,
    a: Asset,
    canvasElement: ElementRef,
    next?: Function
  ): Promise<any> {
    const blob = await this.resizeAndDrawThumbnail(file, canvasElement.nativeElement);
    const blobFile = new File([blob], this.THUMBNAIL.name, { type: this.THUMBNAIL.fileType });
    await this.uploadAndAddThumbnailToAsset(a, blobFile).toPromise();
    if (next) {
      next();
    }
  }

  public async optimizeThumbnail(file: File, asset: Asset, canvasElement: HTMLCanvasElement) {
    // Generate thumbnail
    const blob = await this.resizeAndDrawThumbnail(file, canvasElement);
    // Upload and update asset
    var blobFile = new File([blob], this.THUMBNAIL.name, { type: this.THUMBNAIL.fileType });
    this.uploadAndAddThumbnailToAsset(asset, blobFile).toPromise();
  }

  private simpleUpload(file: File, filename: string): Observable<any> {
    let newName: string;
    return this.initiateSimpleUpload(file.type, filename).pipe(
      switchMap(resp => {
        newName = resp['name'];
        return this.cloudUpload(resp['url'], file.type, file);
      }),
      map(() => newName)
    );
  }

  private initiateSimpleUpload(type: string, filename: string): Observable<any> {
    return this._http.post(
      environment.assetManagerUrl + '/assets/requestUpload',
      {
        type: type,
        name: filename
      }
    );
  }

  private cloudUpload(url: string, type: string, file: File): Observable<any> {
    return this._http.put(url, file, {
      headers: new HttpHeaders({ 'Content-Type': type })
    });
  }

  private resumableUpload(file: File, filename: string): Observable<any> {
    let newName: string;
    this.progress.next({ name: file.name, progress: 0 });
    return this.initiateResumableUpload(file.type, file.size, filename).pipe(
      switchMap(resp => {
        newName = resp['name'];
        return this.uploadChunk(resp['url'], file.type, file, file.size, 0);
      }),
      map(() => newName)
    );
  }

  private initiateResumableUpload(
    type: string,
    length: number,
    filename: string
  ): Observable<any> {
    return this._http.post(
      environment.assetManagerUrl + '/assets/initiateResumableUpload',
      {
        type: type,
        length: length,
        name: filename
      }
    );
  }

  private uploadChunk(
    url: string,
    type: string,
    file: File,
    length: number,
    pointer: number
  ): Observable<any> {
    const chunkSize = this.CHUNK_SIZE;
    const innerBound = pointer;
    const outerBound = Math.min(pointer + chunkSize, length);
    return this._http
      .put(url, file.slice(innerBound, outerBound), {
        headers: new HttpHeaders({
          'Content-Type': type,
          'Content-Range': `bytes ${innerBound}-${outerBound - 1}/${length}`
        }),
        observe: 'response'
      })
      .pipe(
        map(() => {
          return of('complete');
        }),
        catchError(err => {
          if (err.status === 308) {
            const newPointer: number =
              Number(
                err.headers
                  .get('range')
                  .split('-')
                  .pop()
              ) + 1;
            this.progress.next({
              name: file.name,
              progress: newPointer / length
            });
            return this.uploadChunk(url, type, file, length, newPointer);
          } else {
            throw err;
          }
        })
      );
  }
}
