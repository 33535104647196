<div fxLayout="column" fxLayoutAlign="space-evenly center" class="wrapper learnspace-theme">
  <div class="container">
    <ng-container *ngIf="error">
      <div class="info-text">
        <p *ngIf="error === 'rateLimit'">
          Too many requests, please try again later.
        </p>
        <p *ngIf="error !== 'rateLimit'">
          Wonda could not generate the report, please try again.
        </p>
      </div>
      <button mat-raised-button color="primary" (click)="restart()">
        <span>RESTART {{ course?.experience_type | courseLabel | uppercase }}</span>
      </button>
    </ng-container>

    <ng-container *ngIf="(!assessmentResult || !course) && !error">
      <div class="info-text">
        <h2>We're Generating Your Report!</h2>
        <p>
          Wonda is analyzing your conversation to better understand your level of understanding of specific skills.<br>This report will give you insights on how you can improve.
        </p>
      </div>
      <mat-spinner [diameter]="50"></mat-spinner>
    </ng-container>
  
    <ng-container *ngIf="assessmentResult && course">
      <assessment-report
        class="assessment-report"
        [learnerReportMode]="true"
        [courseId]="course.id"
        [courseTitle]="course.title"
        [assessmentSettings]="course?.information?.assessment"
        [assessmentResult]="assessmentResult"
        [canEdit]="canEdit"
        [hasClickedAssessmentButton]="hasClickedAssessmentButton"
        (onRestart)="restart($event)"
        (onOpenAssessmentDashboard)="openAssessmentDashboard($event)"
      ></assessment-report>
    </ng-container>
  </div>
</div>
