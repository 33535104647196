import { ExperienceType, HubMode, LearnspaceMode } from '@shared/models';

export enum StringCase {
  lowercase = 'lowercase',
  uppercase = 'uppercase',
  capitalcase = 'capitalcase',
  titlecase = 'titlecase',
}

export function spaceLabel(mode: HubMode | LearnspaceMode | undefined, plural = false, format: StringCase = StringCase.lowercase): string {
  let label = mode === LearnspaceMode.space ? 'space' : 'program';
  if (plural) {
    label += 's';
  }

  return applyCase(label, format);
}

export function courseLabel(mode: LearnspaceMode | ExperienceType | undefined, plural = false, format: StringCase = StringCase.lowercase): string {
  let label = [LearnspaceMode.space, ExperienceType.mixed, ExperienceType.only360].includes(mode) ? 'experience' : 'simulation';
  if (plural) {
    label += 's';
  }

  return applyCase(label, format);
}

export function participantLabel(mode: LearnspaceMode | undefined, plural = false, format: StringCase = StringCase.lowercase): string {
  let label = mode === LearnspaceMode.space ? 'participant' : 'collaborator';
  if (plural) {
    label += 's';
  }

  return applyCase(label, format);
}

function applyCase(string: string, format: StringCase): string {
  switch (format) {
    case StringCase.lowercase:
      return string.toLowerCase();
    case StringCase.uppercase:
      return string.toUpperCase();
    case StringCase.capitalcase:
      return string.length > 1 ? string.charAt(0).toUpperCase() + string.slice(1) : string.toUpperCase();
    case StringCase.titlecase:
      return string
        .split(' ')
        .map(word => applyCase(word, StringCase.capitalcase))
        .join(' ')
    default:
      return string
  }
}
