import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AssessmentCriteria, AssessmentSettings, Course } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { from } from 'rxjs/internal/observable/from';
import { filter, switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators/mergeMap';

@Component({
  selector: 'assessment-edit',
  templateUrl: './assessment-edit.component.html',
  styleUrls: ['./assessment-edit.component.scss']
})
export class AssessmentEditComponent implements OnInit {
  @Input() course: Course;
  assessment: AssessmentSettings;
  assessmentGeneralForm: FormGroup;
  criteriaListForm: FormGroup
  editionEnabled: boolean;
  newCriteriaTitle: string;
  constructor(
    private _courseApiService: CoursesAPIService,
  ) {
    this.assessmentGeneralForm = new FormGroup({
      enabled: new FormControl(true),
      title: new FormControl('', { updateOn: 'blur' }),
      description: new FormControl('', { updateOn: 'blur' }),
      minScore: new FormControl(1),
      maxScore: new FormControl(5)
    });
  }

  ngOnInit() {
    this.assessment = this.course.information.assessment;
    if (!this.assessment) {
      this.assessment = new AssessmentSettings('', '', []);
    }
    if (!this.assessment.criterias) {
      this.assessment.criterias = [];
    }
    this.assessmentGeneralForm.reset(this.assessment);
    this.toggleAssessment(this.assessmentGeneralForm.controls.enabled.value);

    this.assessmentGeneralForm.valueChanges.pipe(
      mergeMap(() => from(Object.entries(this.assessmentGeneralForm.controls))),
      filter(([label, control]: [string, FormControl]) => control.dirty),
      switchMap(([label, control]: [string, FormControl]) => {
        control.markAsPristine();
        const path = `information.assessment.${label}`;
        return this._courseApiService.patchCourse(this.course.id, { op: 'replace', path, value: control.value })
      })).subscribe();
  }


  /** This function is used as ngFor trackBy, in order to avoid the all list reload/rerender at any nested attribute change */
  trackByIndex(index: number, item: any) {
    return index;
  }

  openReportPage() {
    const url = `analytics/${this.course.learnspace.hub.urlTitle}/${this.course.learnspace.id}/courses/${this.course.id}?activeTab=assessment`;
    window.open(url)
  }

  toggleAssessment(editionEnabled: any) {
    if (editionEnabled) {
      this.assessmentGeneralForm.controls.title.enable();
      this.assessmentGeneralForm.controls.description.enable();
      this.assessmentGeneralForm.controls.minScore.enable();
      this.assessmentGeneralForm.controls.maxScore.enable();
    } else {
      this.assessmentGeneralForm.controls.title.disable();
      this.assessmentGeneralForm.controls.description.disable();
      this.assessmentGeneralForm.controls.minScore.disable();
      this.assessmentGeneralForm.controls.maxScore.disable();
    }
  }

  addCriteria() {
    const newId = this.assessment.criterias.length === 0 ? 0 :
      1 + this.assessment.criterias
        .map(c => c.id)
        .reduce((a, b) => Math.max(a, b));
    const path = `information.assessment.criterias[${this.assessment.criterias.length}]`;
    const value = new AssessmentCriteria(newId, this.newCriteriaTitle, '');
    this._courseApiService.patchCourse(this.course.id, { op: 'add', path, value }).subscribe(
      course => {
        this.assessment = course.information.assessment;
        this.newCriteriaTitle = '';
      }
    )
  }

  canAddCriteria() {
    return this.assessment.criterias.length < 6;
  }

  canDeleteCriteria() {
    return this.assessment.criterias.length > 1;
  }

  removeCriteria(index: number) {
    const path = `information.assessment.criterias[${index}]`;
    this._courseApiService.patchCourse(this.course.id, { op: 'remove', path }).subscribe(
      course => this.assessment = course.information.assessment
    )
  }

  criteriaChanged(event: { data: AssessmentCriteria, index: number }) {
    const path = `information.assessment.criterias[${event.index}]`;
    this._courseApiService.patchCourse(this.course.id, { op: 'replace', path, value: event.data }).subscribe(
      course => this.assessment = course.information.assessment
    )
  }
}
