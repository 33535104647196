import { NgModule } from '@angular/core';
import { AccountEditorComponent } from '@app/account-editor/account-editor.component';
import { AccountDeleteConfirmationComponent } from '@app/account-editor/account-delete-confirmation-dialog/account-delete-confirmation-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { SettingsModule } from '@app/main/settings/settings.module';

@NgModule({
    declarations: [
        AccountEditorComponent,
        AccountDeleteConfirmationComponent,
    ],
    imports: [
        SharedModule,
        SettingsModule
    ]
})
export class AccountEditorModule { }
