import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Invitation, InvitationType, Hub, Learnspace } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'send-invitation',
  templateUrl: './send-invitation.component.html',
  styleUrls: ['./send-invitation.component.scss']
})
export class SendInvitationComponent implements OnInit, AfterViewInit {
  @ViewChild('url') urlInput: ElementRef;

  @Input() userId: string;
  @Input() targetId: string;
  @Input() type: InvitationType;
  @Input() manageRole = false;
  @Input() feedback = true;
  @Input() showDetailList = false;
  @Input() hub: Hub;
  @Input() defaultRole = 'user';
  @Input() canDo: boolean = undefined;

  @Output() done = new EventEmitter(null);

  public instructionMessage = 'Invite new users';
  public emailForm: FormGroup;
  public invitationRequestFeedback: string = null;
  public asyncErrorMessages = {
    isEmail: 'Email incorrect'
  };
  public lsFromHub: Learnspace[];
  public lsList: string[] = [];
  public invitationLink: string = null;
  public splitPattern = new RegExp('[,|;|\n| |\r|\t| < | > ]');

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _courseService: CoursesAPIService,

  ) {
    this.emailForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      role: [this.defaultRole]
    });
  }

  ngOnInit(): void {
    if (this.hub && this.hub.properties && this.hub.properties.invitationUrl) {
      this.invitationLink = this.hub.properties.invitationUrl;
    }

    if (this.hub) {
      if (this.hub.learnspaces == undefined) {
        this._courseService.getLearnspacesByHub(this.hub.id).subscribe(
          lsList => this.lsFromHub = lsList
        )
      } else {
        this.lsFromHub = this.hub.learnspaces;
      }
    }
    if (this.defaultRole === 'admin') {
      this.instructionMessage = 'Add administrators to your hub';
    }
    if (!this.manageRole) {
      this.emailForm.controls.role.disable();
    }

    this.emailForm.valueChanges
      .subscribe(val => {
        if (val.role === 'admin') {
          this.showDetailList = false;
        } else {
          this.showDetailList = true;
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.invitationLink) {
      this.urlInput.nativeElement.setSelectionRange(0, 0);
    }
  }

  public copy(): void {
    this.urlInput.nativeElement.select();
    document.execCommand('copy');
  }

  public openHelp() {
    if (this.hub) {
      window.open('https://help.spaces.wondavr.com/en/articles/3043277-create-and-edit-a-hub#h_d6b8133432');
    } else {
      window.open('http://help.spaces.wondavr.com/en/articles/4149124-join-a-team-space');
    }
  }

  public validateEmail(control: FormControl): Promise<any> {
    const value = control.value;
    const regexp = new RegExp('^[a-zA-Z0-9.!#$\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$', 'g');
    const result: any = regexp.test(value) ? null : {
      isEmail: true
    };
    return result;
  }

  public submitEmail(): void {
    const mails = this.emailForm.controls.email.value;
    const role = this.emailForm.controls.role.value || this.defaultRole;
    const options: any = {};
    if (role) {
      options.role = role;
    }
    if (this.lsList.length !== 0) {
      options.learnspaceList = this.lsList;
    }
    const invitations: Invitation[] = mails.map((mail: any) => { // TODO : declare mail object structure
      return new Invitation(this.userId, mail.display, this.targetId, this.type, options);
    });
    from(invitations)
      .pipe(
        mergeMap(i => {
          return this._courseService.createInvitation(i);
        })
      )
      .subscribe(null, err => {
        this.invitationRequestFeedback = `Error: ${err.message}`;
      }, () => {
        if (this.feedback) {
          this.invitationRequestFeedback = `Your invitation has been sent to ${mails.map((m: any) => m.display).join(', ')}`; // idem
        } else {
          this.done.emit(mails);
        }
      });
  }

  public resetForm(): void {
    this.invitationRequestFeedback = null;
    this.emailForm.reset();
  }

  public isAdminRole(): boolean {
    return this.emailForm.controls.role.value === 'admin';
  }

  public addToList(learnspaceId: string, event: MatCheckboxChange): void {
    if (event.checked) {
      this.lsList.push(learnspaceId);
    } else {
      this.lsList = this.lsList.filter(ls => ls !== learnspaceId);
    }
  }
}
