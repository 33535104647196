import { NgModule } from '@angular/core';
import { UserMenuComponent } from '@app/layout/components/user-menu/user-menu.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common'; 
import { RouterModule } from '@angular/router';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip'; 

@NgModule({
    declarations: [
        UserMenuComponent
    ],
    imports     : [
      MatButtonModule,
      MatMenuModule,
      MatIconModule,
      MatTooltipModule,
      CommonModule,
      MatDividerModule,
      RouterModule

    ],
    exports     : [
        UserMenuComponent
    ]
})
export class UserMenuModule
{
}
