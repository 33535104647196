import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, FormControlName } from '@angular/forms';
// @ts-ignore
import slug from 'slug';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Hub, HubMode, InvitationType, User } from '@shared/models';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Subscription } from 'rxjs/Subscription';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-create-hub',
  templateUrl: './create-hub.component.html',
  styleUrls: ['./create-hub.component.scss']
})
export class CreateHubComponent implements OnInit {
  hubForm: FormGroup;
  suggestUrl = true;
  hub: Hub;
  hubId: string;
  userId: string;
  invitationType = InvitationType.joinHub;
  mailList: any[];
  errorMessage: string = null;
  userStateSub: Subscription;
  mode: HubMode;

  constructor(
    private _authService: AuthenticationService,
    private _courseApiService: CoursesAPIService,
    private _overlayContainer: OverlayContainer,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this._overlayContainer.getContainerElement().classList.add('learnspace-theme');
    this._overlayContainer.getContainerElement().classList.remove('editor-theme');
    // test string urlTitle only contains alphanumeric chars + underscore and dash, without space
    const specialCharRegex = new RegExp('^[a-zA-Z0-9\-\_]*$');
    // test string is not one of the reserved words
    const urlTitleRegex = new RegExp('^(?!(null|undefined|account|analytics|course|createHub|createSpaceHub|createProgramHub|discover|error|hubedit|invitations|login|signup)$).*$');
    this.hubForm = new FormGroup({
      title: new FormControl(null, { validators: [Validators.required] }),
      urlTitle: new FormControl(null,
        Validators.compose([
          Validators.required,
          this.regexValidator(specialCharRegex, { 'specialchars': true }),
          this.regexValidator(urlTitleRegex, { 'reserved': true })
        ]),
        this.unicityValidator()),
      hub_type: new FormControl('', { validators: [Validators.required] }),
      logo_id: new FormControl(''),
    }, { updateOn: 'blur' });
  }

  ngOnInit(): void {
    this.mode = /^createProgramHub/i.test(this._route.snapshot.url.toString()) ? HubMode.program : HubMode.space;
    this._authService.getWondaUser().then(user => {
      this.userId = user.id;
      if (!user || !user.Hubs || !this.canCreateHub(user)) {
        this._router.navigate(['..']); //go to root and is redirected to default hub
      }
    })

    if (this.suggestUrl) {
      this.hubForm.controls['title'].valueChanges.subscribe(title => {
        this.hubForm.controls['urlTitle'].setValue(this.slugifyTitle(title));
      });
    }
  }

  /** allow to create a hub of each mode type or if user is platform admin */
  canCreateHub(user: User): boolean {
    if (user.role == 'admin') {
      return true;
    }
    return user.Hubs.find(hub => hub.mode === this.mode) === undefined;
  }

  unicityValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<{ [key: string]: any } | null> => {
      return this._courseApiService.checkUnicity(control.value).toPromise().then(resp => {
        if (!resp.unicity) {
          return { 'checkUnicity': true };
        }
      });
    };
  }

  slugifyTitle(title: string): string {
    return slug(title);
  }

  createHub(): void {
    const hubData = {
      ...this.hubForm.value,
      mode: this.mode
    };
    this.errorMessage = null;
    this._courseApiService.createHub(hubData).subscribe(hub => {
      this._router.navigate(['..', hub.urlTitle]);
    }, error => {
      this.errorMessage = error.error.message;
      console.log(error);
    });
  }

  addLogoId(event: string): void {
    this.hubForm.controls['logo_id'].setValue(event);
  }

  storeEmail(event: any): void {
    this.mailList = event;
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }
}
