import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Annotation } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { mergeMap } from 'rxjs/operators/mergeMap';

@Component({
  selector: 'character-prompt',
  templateUrl: './character-prompt.component.html',
  styleUrls: ['./character-prompt.component.scss']
})
export class CharacterPromptComponent implements OnInit {
  @Input() npc: Annotation;
  plainTextForm: FormGroup;
  promptCategories: { title: string, description: string }[];
  newCategoryTitle: string;
  constructor(
    private _courseApiService: CoursesAPIService
  ) {
    this.plainTextForm = new FormGroup({
      prompt: new FormControl('', { updateOn: 'blur' })
    });
  }

  ngOnInit(): void {
    const prompt = this.getPrompt()
    this.plainTextForm.controls.prompt.setValue(prompt);
    this.parsePrompt(prompt);
    this.plainTextForm.valueChanges.pipe(
      mergeMap((value: any) => {
        return this._courseApiService.patchAnnotation(
          this.npc.id,
          { op: 'replace', path: 'properties.definingConversation.messages[0].content', value: value.prompt }
        );
      })
    ).subscribe((npc: Annotation) => {
      this.npc = npc;
      this.parsePrompt(this.getPrompt());
    });
  }

  /** This function is used as ngFor trackBy, in order to avoid the all list reload/rerender at any nested attribute change */
  trackByIndex(index: number, item: any) {
    return index;
  }

  getPrompt() {
    return this.npc?.properties?.definingConversation?.messages[0]?.content;
  }

  parsePrompt(prompt: string) {
    const categories = prompt.split(/##\s*/).filter(Boolean);
    this.promptCategories = categories.map(section => {
      const separatorIndex = section.indexOf('\n');
      const title = section.substring(0, separatorIndex).trim();
      const description = section.substring(separatorIndex + 1).trim();
      return { title, description };
    });
  }

  categoryChanged(event: { data: any, index: number }) {
    this.promptCategories[event.index] = event.data;
    this.saveCategoryChange();
  }

  addCategory() {
    this.promptCategories.push({ title: this.newCategoryTitle, description: '' });
    this.saveCategoryChange();
  }

  removeCategory(index: number) {
    this.promptCategories.splice(index, 1);
    this.saveCategoryChange();
  }

  saveCategoryChange() {
    let prompt = '';
    this.promptCategories.map((cat) => {
      prompt = prompt.concat(`##${cat.title}\n${cat.description}\n\n`);
    });
    this.plainTextForm.controls.prompt.setValue(prompt);
    this._courseApiService.patchAnnotation(
      this.npc.id,
      { op: 'replace', path: 'properties.definingConversation.messages[0].content', value: prompt }
    ).subscribe(npc => {
      this.npc = npc;
      this.newCategoryTitle = '';
    });
  }
}
