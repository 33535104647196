<div class="edition-container learnspace-theme">
  <ng-container *ngIf="course && npc">
    <div class="edition-part">
      <h2>{{ getTitle() }}</h2>
      <mat-drawer-container>
        <mat-drawer mode="side" opened>
          <mat-list role>
            <mat-list-item [ngClass]="{'step--active': stepper.selectedIndex == 0}" (click)="goToStep(0)">
              <mat-icon fontSet="fa" fontIcon="fa-home"></mat-icon>
              <span matListItemTitle>OVERVIEW</span>
            </mat-list-item>
            <mat-list-item [ngClass]="{'step--active': stepper.selectedIndex == 1}" (click)="goToStep(1)">
              <mat-icon fontSet="fa" fontIcon="fa-mountain-city"></mat-icon>
              <span matListItemTitle>LOCATION</span>
            </mat-list-item>
            <mat-list-item [ngClass]="{'step--active': (stepper.selectedIndex >= 2 && stepper.selectedIndex < 5)}"
              (click)="goToStep(2)">
              <mat-icon fontSet="fa" fontIcon="fa-person"></mat-icon>
              <span matListItemTitle>CHARACTER</span>
            </mat-list-item>
            <div class="character-list">
              <mat-list-item [ngClass]="{'step--active': stepper.selectedIndex == 2}" (click)="goToStep(2)">
                <mat-icon fontSet="fa" fontIcon="fa-address-card"></mat-icon>
                <span matListItemLine>IDENTITY</span>
              </mat-list-item>
              <mat-list-item [ngClass]="{'step--active': stepper.selectedIndex == 3}" (click)="goToStep(3)">
                <mat-icon fontSet="fa" fontIcon="fa-palette"></mat-icon>
                <span matListItemLine>APPEARENCE</span>
              </mat-list-item>
              <mat-list-item [ngClass]="{'step--active': stepper.selectedIndex == 4}" (click)="goToStep(4)">
                <mat-icon fontSet="fa" fontIcon="fa-message"></mat-icon>
                <span matListItemLine>PROMPT</span>
              </mat-list-item>
            </div>
            <mat-list-item [ngClass]="{'step--active': stepper.selectedIndex == 5}" (click)="goToStep(5)">
              <mat-icon fontSet="fa" fontIcon="fa-award"></mat-icon>
              <span matListItemTitle>ASSESSMENT</span>
            </mat-list-item>
          </mat-list>
          <div class="action-buttons">
            <button mat-raised-button color="primary" (click)="launch()">LAUNCH</button>
            <button mat-button color="primary" (click)="cancel()">{{ createMode ? 'CANCEL':'BACK' }} </button>
          </div>
        </mat-drawer>
        <mat-drawer-content>
          <mat-horizontal-stepper #stepper (selectionChange)="changeParams($event)">
            <mat-step>
              <h3>Set the main informations</h3>
              <simulation-main-settings [course]="course"
                (courseChanged)="onCourseChange($event)"></simulation-main-settings>
              <mat-divider></mat-divider>
              <div class="bottom-buttons">
                <button mat-raised-button color="primary" matStepperNext>
                  <mat-icon fontSet="fa" fontIcon="fa-chevron-right"></mat-icon>
                  NEXT
                </button>
              </div>
            </mat-step>
            <mat-step>
              <ng-template matStepContent>
                <!-- <ng-template matStepLabel>ENVIRONMENT</ng-template> -->
                <h3>Select the environment</h3>
                <media-picker [asset]="course.course_skybox" [assetType]="AT.i360" [title]="'Select or add a skybox'"
                  (selected)="updateSkybox($event)"></media-picker>
                <mat-divider></mat-divider>
                <div class="bottom-buttons">
                  <button mat-stroked-button color="primary" matStepperPrevious>BACK</button>
                  <button mat-raised-button color="primary" matStepperNext>
                    <mat-icon fontSet="fa" fontIcon="fa-chevron-right"></mat-icon>
                    NEXT
                  </button>
                </div>
              </ng-template>
            </mat-step>
            <mat-step>
              <ng-template matStepContent>
                <!-- <ng-template matStepLabel>CHARACTER/GENERAL</ng-template> -->
                <h3>Create your character</h3>
                <character-edit [npc]="npc"></character-edit>
                <mat-divider></mat-divider>
                <div class="bottom-buttons">
                  <button mat-stroked-button color="primary" matStepperPrevious>BACK</button>
                  <button mat-raised-button color="primary" matStepperNext>
                    <mat-icon fontSet="fa" fontIcon="fa-chevron-right"></mat-icon>
                    NEXT
                  </button>
                </div>
              </ng-template>
            </mat-step>
            <mat-step>
              <ng-template matStepContent>
                <!-- <ng-template matStepLabel>CHARACTER/APPEARENCE</ng-template> -->
                <h3>Set your character appearance</h3>
                <media-picker [asset]="npc.media" [assetType]="AT.npc" [title]="'Select or add an avatar'"
                  [forceContain]="true" (selected)="updateNpc($event)"></media-picker>
                <mat-divider></mat-divider>
                <div class="bottom-buttons">
                  <button mat-stroked-button color="primary" matStepperPrevious>BACK</button>
                  <button mat-raised-button color="primary" matStepperNext>
                    <mat-icon fontSet="fa" fontIcon="fa-chevron-right"></mat-icon>
                    NEXT
                  </button>
                </div>
              </ng-template>
            </mat-step>
            <mat-step>
              <!-- <ng-template matStepContent>  do not lazy load this one to avoid wrong visual effect at component init-->
                <!-- <ng-template matStepLabel>CHARACTER/PERSONALITY</ng-template> -->
                <h3>Define your character identity & goal</h3>
                <character-prompt [npc]="npc"></character-prompt>
                <mat-divider></mat-divider>
                <div class="bottom-buttons">
                  <button mat-stroked-button color="primary" matStepperPrevious>BACK</button>
                  <button mat-raised-button color="primary" matStepperNext>
                    <mat-icon fontSet="fa" fontIcon="fa-chevron-right"></mat-icon>
                    NEXT
                  </button>
                </div>
              <!-- </ng-template> -->
            </mat-step>
            <mat-step>
              <ng-template matStepContent>
                <!-- <ng-template matStepLabel>ASSESSMENT</ng-template> -->
                <h3>Assess your learners performance</h3>
                <assessment-edit [course]="course"></assessment-edit>
                <mat-divider></mat-divider>
                <div class="bottom-buttons">
                  <button mat-stroked-button color="primary" matStepperPrevious>BACK</button>
                  <button mat-raised-button color="primary" (click)="launch()">
                    <mat-icon matPrefix fontSet="fa" fontIcon="fa-play"></mat-icon>
                    LAUNCH
                  </button>
                </div>
              </ng-template>
            </mat-step>
          </mat-horizontal-stepper>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
    <div class="preview-part">
      <div class="preview-button-wrapper">
        <button mat-raised-button color="primary" (click)="launch()">
          START CONVERSATION
        </button>
      </div>
    </div>
  </ng-container>
</div>