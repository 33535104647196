export enum PlanType {
  // Space Hubs
  free = 'free',
  essential = 'essential',
  campus = 'campus',
  publisher = 'publisher',
  agency = 'agency',
  basic = 'basic',
  plus = 'plus',
  premium = 'premium',
  // Program Hubs
  trial = 'trial',
  starter = 'starter',
  starterPlus = 'starterPlus'
}

export const isFreePlan = (plan: Plan | PlanType): boolean => {
  if (plan instanceof Plan) {
    return isFreePlan(plan.type);
  }

  return [PlanType.free, PlanType.trial].includes(plan);
};

export class Plan {
  constructor(
    public type: PlanType,
    public isPaid: boolean,
    public allowIntegrations: boolean,
    public allowSSO: boolean,
    public allowLTI: boolean,
    public allowMultipleLTIPlatforms: boolean,
    public forceWatermark: boolean,
    public maxSpace: number,
    public maxCourse: number
  ) {}
}

export class PlanResponse {
  constructor(
    public type: PlanType,
    public isPaid: boolean,
    public allowIntegrations: boolean,
    public allowSSO: boolean,
    public allowLTI: boolean,
    public allowMultipleLTIPlatforms: boolean,
    public forceWatermark: boolean,
    public maxSpace: number | string,
    public maxCourse: number | string
  ) {}
}