import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, QueryList, ViewChildren, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import {Asset, AssetType, LearnspaceMode} from '@shared/models';
import {CoursesAPIService} from '@shared/services/coursesApi.service';
import {CurrentContextService} from '@shared/services/currentContextService';
import {CurrentHubService} from '@shared/services/currentHub.service';

@Component({
  selector: 'file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {
  @ViewChildren('displayedItem') displayedItem: QueryList<ElementRef>;
  @ViewChild('fileManagerTabGroup', { static: false }) fileManagerTabGroup: MatTabGroup;

  type: AssetType;
  courseId: string;
  isVideo: boolean;
  openOnTab: number; //if equals -1 then don't show tabs at all
  ibmTab: boolean;
  kalturaTab: boolean;
  readyPlayerMeTab: boolean;
  canAddLink: boolean;
  hubMode: LearnspaceMode;

  constructor(
    private _dialogRef: MatDialogRef<FileManagerComponent>,
    private _courseApiService: CoursesAPIService,
    private currentContext: CurrentContextService,
    private _currentHub: CurrentHubService,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public _data: any //TODO declare model
  ) {}

  ngOnInit(): void {
    this._dialogRef.updateSize('50vw', '70vh');
    this.type = this._data.type as AssetType;
    this.isVideo = this.type === AssetType.v2D || this.type === AssetType.v360;
    this.courseId = this.currentContext.currentCourse;
    this.ibmTab = this.showIBMTab();
    this.kalturaTab = this.showKalturaTab();
    this.readyPlayerMeTab = this.showReadyPlayerMeTab();
    this.openOnTab = this._data.openOnTab !== undefined ? this._data.openOnTab : 0;
    this.canAddLink = this._currentHub.hub.properties.enableLiveLink;
    this.hubMode = this._currentHub.hub.mode;
  }

  onCurrentSelect(selection: Asset): void {
    if (this.courseId) {
      this._courseApiService
        .addMediaToCourse(selection, this.courseId)
        .subscribe(() => {
          this._dialogRef.close(selection);
        });
    } else {
      console.warn('No current course');
      this._dialogRef.close(selection);
    }
  }

  onClose(): void {
    this._dialogRef.close();
  }

  public showIBMTab(): boolean {
    return this.isVideo && this._currentHub.integrations['ibm'] === true;
  }

  public showKalturaTab(): boolean {
    return this._currentHub.integrations && this._currentHub.integrations['kaltura'] !== undefined && this.type !== AssetType.npc && this.type !== AssetType.o3D;
  }

  public showReadyPlayerMeTab(): boolean {
    return this.type === AssetType.npc;
  }

  public goToHelp(): void {
    window.open('https://help.spaces.wondavr.com/en/articles/2630822-kaltura-integration');
  }
}
