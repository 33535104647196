<div class="characteur-edit">
  <form [formGroup]="characterEditForm" class="characteur-edit-form">
    <mat-form-field class="fixed-width-field">
      <mat-label>Character name</mat-label>
      <input matInput formControlName="name" maxlength="50" #nameInput>
      <mat-hint align="end">{{nameInput.value?.length || 0}}/50</mat-hint>
    </mat-form-field>
    <div class="language-settings">
      <mat-form-field class="fixed-width-field">
        <mat-label>Spoken language</mat-label>
        <mat-select formControlName="spokenLanguage">
          <ng-container *ngFor="let language of languageList">
            <mat-option [value]="language[1]">
              {{ language[0] }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="fixed-width-field">
        <mat-label>Voice</mat-label>
        <mat-select formControlName="selectedVoice">
          <ng-container *ngFor="let voice of voiceList">
            <mat-option [value]="voice[0]">
              {{ voice[1].name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field class="intro-message">
      <mat-label>Character's intro message</mat-label>
      <textarea matInput formControlName="introMessage"></textarea>
    </mat-form-field>
  </form>
</div>
<button mat-raised-button color="primary" [disabled]="!enablePreview()" (click)="introPreview()">
  <mat-icon matPrefix fontSet="fa" fontIcon="fa-play"></mat-icon>
  PREVIEW</button>
<audio #audioprev></audio>