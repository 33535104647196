import { Component, AfterContentChecked, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Course } from '@shared/models/course.model';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { CurrentContextService } from '@shared/services/currentContextService';
import { AssetType, Annotation } from '@shared/models';
import { LinkService } from '@shared/services/link.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { filter } from 'rxjs/internal/operators/filter';
import { switchMap } from 'rxjs/internal/operators/switchMap';

@Component({
  selector: 'simulation-edit',
  templateUrl: './simulation-edit.component.html',
  styleUrls: ['./simulation-edit.component.scss']
})

export class SimulationEditComponent implements AfterContentChecked {
  public course: Course;
  public npc: Annotation;
  public createMode = false;
  public AT = AssetType;
  public title: string;

  @ViewChild('stepper') stepper: MatStepper;
  constructor(
    private _courseApiService: CoursesAPIService,
    private _currentContext: CurrentContextService,
    private _dialog: MatDialog,
    private _linkService: LinkService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
    this._currentContext.courseId = this._route.snapshot.params.courseId;
    this._courseApiService.getCourseById(this._currentContext.courseId)
      .toPromise()
      .then(course => {
        this.course = course;
        this._currentContext.currentLearnspace = this.course.learnspace_id;
      });

    this._courseApiService.getCourseMainNPC(this._currentContext.courseId)
      .toPromise()
      .then(annotation => {
        this.npc = annotation;
      })
  }

  ngAfterContentChecked(): void { //stepper is defined only after content init
    this._route.queryParams.subscribe(params => {
      if (params.step != undefined) {
        this.goToStep(params.step);
      }
    });
  }

  cancel() {
    if (this.createMode) {
      this._dialog
        .open(ConfirmationDialogComponent, {
          width: '400px',
          height: '200px',
          panelClass: ['learnspace-theme'],
          data: { title: 'Discard change', type: 'simulation' }
        })
        .afterClosed()
        .pipe(
          filter(val => val === 'ok'),
          switchMap(() => { return this._courseApiService.deleteCourse(this.course.id) })
        )
        .subscribe(() => this.goToParentProgram());
    } else {
      this.goToParentProgram();
    }
  }

  changeParams(event: any) {
    const params: Params = { step: event.selectedIndex };
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  getTitle(): string {
    if (this.createMode) {
      return 'Create a New Simulation';
    } else {
      return `Edit: ${this.course.title}`;
    }
  }

  goToParentProgram() {
    this._router.navigate(['../..'], { relativeTo: this._route });
  }

  launch() {
    this._linkService.launchCourse(this.course.id, this.course.is_multi, true, false, this.course.information.versioningEnabled, this.course.information.saveProgressionEnabled);
  }

  onCourseChange(event: Course) {
    this.course = Object.assign(this.course, event);
  }

  goToStep(index: number) {
    if (!this.stepper) return;
    this.stepper.selectedIndex = index;
  }

  updateSkybox(event: any) {
    this._courseApiService.updateCourse(
      this.course.id,
      { skybox_id: event.id },
      false
    ).subscribe(course => this.course = course);
  }

  updateNpc(event: any) {
    this._courseApiService.updateAnnotation(
      this.npc.id,
      { media_id: event.id },
    ).subscribe(npc => this.npc = npc);
  }

}
