export const environment = {
  production: true,
  hmr: false,
  version: require('../../package.json').version,
  roomEditorUrl: 'https://spaces.wondavr.com/room-editor',
  coursesApiUrl: 'https://crud.spaces.wondavr.com',
  coursesSocketUrl: 'wss://crud.spaces.wondavr.com/websocket',
  assetManagerUrl: 'https://eu-assets-manager.spaces.wondavr.com',
  contentBucketPrefix: 'https://eu-content.spaces.wondavr.com',
  assetsBucketPrefix: 'https://eu-assets.spaces.wondavr.com',
  roomUrl: 'https://spaces.wondavr.com/embed/',
  analyticsServiceUrl: 'https://eu-analytics.spaces.wondavr.com',
  templateFile: 'https://eu-assets.spaces.wondavr.com/templates/templates.json',
  programsTemplateFile: 'https://eu-assets.spaces.wondavr.com/templates/programs_templates.json',
  voiceSelectionFile: `https://eu-assets.spaces.wondavr.com/voice-config/tts-voices-config.json`,
  languagePresetFile: `https://eu-assets.spaces.wondavr.com/languages/language-preset.json`,
  cookieSubdomain: '.wondavr.com',
  firebase: {
      apiKey: 'AIzaSyBy6uag-Znrp02pT9HvMAKH7TBnpGPg5ew',
      authDomain: 'eu-prod-spaces.firebaseapp.com',
      databaseURL: 'https://eu-prod-spaces.firebaseio.com',
      projectId: 'eu-prod-spaces',
      storageBucket: '',
      messagingSenderId: '322574128935'
  },
  quizDefaultImageId: '8d1c4020-e1b1-11e8-b05d-b10e29addf14',
  intercom_app_id: 'o3jzpid6',
  heap_id: '1572261464',
  welcomeMessageUrl: 'https://wondavr.com/welcome/welcome.html',
  imlabHubId: 'e6f02ee0-7883-11e9-b100-e5591d5740a0',
  googleAnalyticsFlowId: 'G-W8G7KZBHXF'
};
