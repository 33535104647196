import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Hub, InvitationType, ListQueryOptions } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CurrentHubService } from '@shared/services/currentHub.service';
import { User } from '@shared/models/user.model';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { ManageUserAccessComponent } from '@app/main/hub/manage-user-access/manage-user-access.component';
import { SendInvitationComponent } from '@shared/components/send-invitation/send-invitation.component';
import { InternalAnalyticsService } from '@shared/services/internalAnalytics.service';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { switchMap, filter, map } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BreadCrumb } from '@shared/components/breadcrumbs/breadcrumb.model';

@Component({
  selector: 'hub-editor',
  templateUrl: './hub-edit.component.html',
  styleUrls: ['./hub-edit.component.scss']
})
export class HubEditComponent implements OnInit {
  hub: Hub;
  hubUsers: User[];
  hubForm: FormGroup;
  displayedColumns: string[] = ['name', 'email', 'role', 'actions'];
  planExpiredMessage: string = null;
  contactSalesMessage: string;
  isInTrial = false;
  trialDaysLeft = 0;
  planEndDate: Date;
  currentSearch = '';
  private _listUsersOption = new ListQueryOptions('-createdAt', null, 0, 30, true);
  private _hubUsersTotal = 0;
  public breadcrumbs: BreadCrumb[];
  public isFetchingUsers = false;

  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  constructor(
    private _courseService: CoursesAPIService,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthenticationService,
    private _currentHubService: CurrentHubService,
    private _router: Router,
    private _internalAnalyticsService: InternalAnalyticsService,
    private _overlayContainer: OverlayContainer
  ) {
    this._overlayContainer.getContainerElement().classList.add('learnspace-theme');
    this.hubForm = new FormGroup({
      title: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)] }),
      logo_id: new FormControl(''),
      properties: new FormGroup({
        displayWatermark: new FormControl(false)
      })
    });
  }

  ngOnInit(): void {
    this._activatedRoute.paramMap.pipe(
      map(((params: ParamMap) => params.get('urlTitle'))),
      switchMap((hub_id: string) => {
        return this._courseService.getHub(hub_id, null, true);
      })
    ).subscribe((hub: Hub) => {
      this.hub = hub;
      this.hubForm.reset(hub);
      this.setUserList(hub.urlTitle);
      this.setPlanInfo(hub);

      // Init Breadcrumbs
      this.breadcrumbs = [
        new BreadCrumb(null, 'home', () => {
          this._router.navigate([this.hub.urlTitle]);
        }),
        new BreadCrumb('General Settings', null, null)
      ]
    })
  }

  setPlanInfo(hub: Hub) {
    this._currentHubService.setCurrentHub(hub).then(() => {
      if (!this._currentHubService.isAdminOfHub(this._authService.wondaUser, hub.id)) {
        return this._router.navigate(['/error'], { queryParams: { status: 403 } });
      }
      this.isInTrial = this._currentHubService.isFree();
      if (this.isInTrial) {
        this.planExpiredMessage = `These options are not available with the ${hub.plan} plan.`;
      } else {
        this.planExpiredMessage = 'These options are not available with your plan.';
      }
      this.trialDaysLeft = this._currentHubService.planTimeLeft();
      this.planEndDate = this._currentHubService.planExpiration();
    })
  }

  setUserList(hubUrlTitle: string): void {
    // Reset list query options
    this.currentSearch = null;
    this._listUsersOption.search = null;
    this._listUsersOption.offset = 0;
    this._listUsersOption.total = true;
    this._courseService.getHubUsers(hubUrlTitle, this._listUsersOption).subscribe(paginatedUsers => {
      this.hubUsers = paginatedUsers.users;
      this._hubUsersTotal = paginatedUsers.total;
      this._listUsersOption.total = false;
      this._listUsersOption.offset += this.hubUsers.length;
    })
  }

  addLogoId(event: string): void {
    this.hubForm.controls['logo_id'].setValue(event);
  }
  save(): void {
    const properties = { ...this.hub.properties, displayWatermark: this.hubForm.controls.properties.value.displayWatermark };
    this._courseService
      .updateHub(this.hub.id, { title: this.hubForm.controls.title.value, logo_id: this.hubForm.controls.logo_id.value, properties })
      .subscribe(hub => {
        this._router.navigate([hub.urlTitle]);
      });
  }

  editUser(user: User): void {
    const dialogRef = this._dialog.open(ManageUserAccessComponent, {
      width: '40vw',
      height: '50vh',
      panelClass: 'learnspace-theme'
    });

    dialogRef.componentInstance.user = user;
    dialogRef.componentInstance.hub = this.hub;
  }

  inviteNewUserToHub(): void {
    const sendInvitDialog = this._dialog.open(SendInvitationComponent, {
      width: '48vw',
      height: '62vh',
      panelClass: ['learnspace-theme', 'invite-new-user-dialog']
    });

    sendInvitDialog.componentInstance.manageRole = true;
    sendInvitDialog.componentInstance.feedback = true;
    sendInvitDialog.componentInstance.targetId = this.hub.id;
    sendInvitDialog.componentInstance.type = InvitationType.joinHub;
    sendInvitDialog.componentInstance.showDetailList = true;
    sendInvitDialog.componentInstance.hub = this.hub;
    sendInvitDialog.componentInstance.done.subscribe(() => this._dialog.closeAll());
  }
  kickUser(user: User): void {
    this._dialog
      .open(ConfirmationDialogComponent, {
        width: '400px',
        height: 'auto',
        panelClass: 'learnspace-theme',
        data: {
          title: 'Unsubscribe this user',
          type: 'user',
          action: 'unsubscribe'
        }
      })
      .afterClosed()
      .pipe(
        filter(val => val === 'ok'),
        switchMap(a => {
          return this._courseService.dissociateUserFromHub(this.hub.id, user.id);
        })
      )
      .subscribe(() => {
        this.hubUsers = this.hubUsers.filter((u) => {
          return u.id !== user.id;
        });
        this.table.renderRows();
      });

  }
  updateUserRole(event: any, user: User): void {
    this._courseService.associateUserToHub(this.hub.id, user.id, event.value)
      .subscribe(() => {
        const index = this.hubUsers.findIndex(participant => participant.id === user.id);
        this.hubUsers[index].HubsUsers.role = event.value;
      });
  }
  contactSales(): void {
    this._internalAnalyticsService.showNewMessage();
  }
  onTableScroll(e: any): void {
    if (this.isFetchingUsers) return;
    const tableViewHeight = e.target.offsetHeight;
    const tableScrollHeight = e.target.scrollHeight;
    const scrollLocation = e.target.scrollTop;
    const needToFetchRemainingUsers = this.hubUsers.length < this._hubUsersTotal;
    if (tableScrollHeight - tableViewHeight - scrollLocation < 5 && needToFetchRemainingUsers) {
      const _listUsersOption = new ListQueryOptions(this._listUsersOption.sort, this._listUsersOption.search, this._listUsersOption.offset, 10, true);
      this.isFetchingUsers = true;
      this._courseService.getHubUsers(this.hub.urlTitle, _listUsersOption).subscribe((paginatedHubUser) => {
        this.isFetchingUsers = false;
        this.hubUsers = this.hubUsers.concat(paginatedHubUser.users);
        this._listUsersOption.offset = this.hubUsers.length;
      });
    }
  }
  setSearch(): void {
    if (this.isFetchingUsers) return;
    // Search bar searches for text in email, first_name and last_name fields
    this._listUsersOption.search = `email:%${this.currentSearch}%|first_name:%${this.currentSearch}%|last_name:%${this.currentSearch}%`;
    this._listUsersOption.offset = 0;
    this._listUsersOption.total = true;
    this._courseService.getHubUsers(this.hub.urlTitle, this._listUsersOption).subscribe((paginatedHubUser) => {
      this._hubUsersTotal = paginatedHubUser.total;
      this.hubUsers = paginatedHubUser.users;
      this._listUsersOption.offset = this.hubUsers.length;
    });
  }
  clearSearch(): void {
    if (this.isFetchingUsers) return;
    this.currentSearch = null;
    this._listUsersOption.search = null;
    this._listUsersOption.offset = 0;
    this._listUsersOption.total = true;
    this._courseService.getHubUsers(this.hub.urlTitle, this._listUsersOption).subscribe((paginatedHubUser) => {
      this._hubUsersTotal = paginatedHubUser.total;
      this.hubUsers = paginatedHubUser.users;
      this._listUsersOption.offset = this.hubUsers.length;
    });
  }
}
