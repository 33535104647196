import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { LoginComponent } from '@app/main/login/login.component';
import { SignUpComponent } from '@app/main/login/signUp.component';
import { PasswordResetComponent } from '@app/main/login/password-reset/password-reset.component';
import { SharedModule } from '@shared/shared.module';
import { UnAuthGuard } from '@app/guards/unauth.guard';
import { SearchHubByDomainComponent } from '@app/main/login/search-hub-by-domain/search-hub-by-domain.component';


const routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnAuthGuard]
  }, {
    path: 'signup',
    component: SignUpComponent,
    canActivate: [UnAuthGuard]
  }
];

@NgModule({
    declarations: [
        LoginComponent,
        SignUpComponent,
        PasswordResetComponent,
        SearchHubByDomainComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        HttpModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        SharedModule
    ],
    exports: [LoginComponent],
    providers: []
})
export class LoginModule {
}
