import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentResult, Course, ExperienceType } from '@shared/models';
import { AssessmentService } from '../../shared/services/assessment.service';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CourseAnalyticsTab } from '@shared/services/analytics.service';
import { environment } from '@env/environment';

enum Error {
  'default' = 'default',
  'rateLimit' = 'rateLimit',
}

@Component({
  selector: 'learner-report',
  templateUrl: './learner-report.component.html',
  styleUrls: ['./learner-report.component.scss'],
})
export class LearnerReportComponent implements OnInit {
  public course?: Course;
  public canEdit: boolean = false;
  public hasClickedAssessmentButton: boolean = false;
  public assessmentResult?: AssessmentResult;
  public error?: Error;

  constructor(
    private _courseService: CoursesAPIService,
    private _assessmentService: AssessmentService,
    private _authenticationService: AuthenticationService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    // If assessment report is embedded in the player
    if (window.parent) {
      const topBarEl: HTMLElement = document.querySelector('.navbar');
      if (topBarEl) {
        topBarEl.style.display = 'none';
      }
    }

    this._route.paramMap.subscribe(async (params) => {
      const wondaUser = await this._authenticationService.getWondaUser();
      this.hasClickedAssessmentButton = wondaUser
        ? wondaUser.feature_usage?.hasClickedAssessmentButton
        : true;

      const courseId = params.get('courseId');
      const sessionId = params.get('sessionId');
      this.loadData(courseId, sessionId);
    });
  }

  private loadData(courseId: string, sessionId: string) {
    this._courseService.getCourseViewLazy(courseId).subscribe((course) => {
      this.course = course;
      this.canEdit = course.learnspace.isParticipantOrHubAdmin;
    });

    this._assessmentService.getAssessmentBySessionId(sessionId).subscribe(
      (existingAssessment) => {
        this.assessmentResult = existingAssessment;
      },
      (e) => {
        if (e.status !== 404) {
          this.error = Error.default;
          return;
        }
        const creatorId = this._authenticationService.wondaUser
          ? this._authenticationService.wondaUser.id
          : undefined;
        this._assessmentService
          .generate(sessionId, courseId, creatorId, 'learner-view')
          .subscribe(
            (assessmentResult) => {
              this.assessmentResult = assessmentResult;
            },
            (e) => {
              this.error = e.status === 429 ? Error.rateLimit : Error.default;
            }
          );
      }
    );
  }

  restart() {
    // If assessment report is embedded in the player
    if (window.parent) {
      window.parent.postMessage('restartExperience', environment.roomUrl);
    } else {
      history.back();
    }
  }

  async openAssessmentDashboard(editingMode: boolean = false) {
    await this.updateFeatureUsageIfNeeded();

    if (editingMode && this.course?.experience_type === ExperienceType.ai) {
      // In this case, the assessment should be edited in the simplified editor.
      // Otherwise, it should be edited in the legacy dialog in the dashboard view.
      const url = `${this.course.learnspace.hub.urlTitle}/${this.course.learnspace_id}/simulations/${this.course.id}`;
      this._router.navigate([url], { queryParams: { step: 5 } });
      return;
    }

    const url = `analytics/${this.course.learnspace.hub.urlTitle}/${this.course.learnspace_id}/courses/${this.course.id}`;
    this._router.navigate([url], {
      queryParams: {
        activeTab: CourseAnalyticsTab.Assessment,
      },
      fragment: editingMode ? 'editor' : undefined
    });
  }

  private async updateFeatureUsageIfNeeded() {
    if (this.hasClickedAssessmentButton) return;

    const feature_usage = { hasClickedAssessmentButton: true };
    await this._authenticationService
      .updateMyUser({ feature_usage })
      .toPromise();
  }
}
