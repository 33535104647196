<table>
  <tr>
    <td>Name</td>
    <td>{{ user.first_name }} {{ user.last_name }}</td>
  </tr>
  <tr>
    <td>Email</td>
    <td>{{ user.email }}</td>
  </tr>
  <tr>
    <td>Type</td>
    <td>
      <mat-select [(ngModel)]="role" style="width:70px">
        <mat-option value="admin">Admin</mat-option>
        <mat-option value="user">User</mat-option>
      </mat-select>
    </td>
  </tr>
  <tr>
    <td>Participate to</td>
    <td>
      <ng-container *ngIf="!isAdmin(); else adminMsg">
        <div *ngFor="let space of hub.learnspaces">
          <mat-checkbox [checked]="isInUserList(space.id)" (change)="addToList(space.id, $event)"> {{ space.title }}
          </mat-checkbox>
        </div>
      </ng-container>
      <ng-template #adminMsg>
        <span style="font-style:italic">Admin has access to all {{ hub.mode | spaceLabel:true }}</span>
      </ng-template>
    </td>
  </tr>
</table>
<div class="bottom-buttons">
  <button mat-button (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="commitChanges()"> OK </button>
</div>