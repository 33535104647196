import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { Annotation } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { from } from 'rxjs/internal/observable/from';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export interface Voice {
  name: string,
  model_id: string,
  optimize_streaming_latency?: number,
  voice_settings?: {
    stability: number,
    similarity_boost: number
  },
  voice_id: string
}

@Component({
  selector: 'character-edit',
  templateUrl: './character-edit.component.html',
  styleUrls: ['./character-edit.component.scss']
})

export class CharacterEditComponent implements OnInit {
  @ViewChild('audioprev', { static: false })
  public audioElm: ElementRef;

  @Input() npc: Annotation;
  languageList: any[][];
  voiceList: [string, Voice][];
  characterEditForm: FormGroup;

  constructor(
    private _courseApiService: CoursesAPIService,
    private _http: HttpClient,
    private _overlayContainer: OverlayContainer,
  ) {
    this._overlayContainer.getContainerElement().classList.remove('editor-theme');
    this._overlayContainer.getContainerElement().classList.add('learnspace-theme');
    this.characterEditForm = new FormGroup({
      name: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)], updateOn: 'blur' }),
      spokenLanguage: new FormControl(''),
      selectedVoice: new FormControl(''),
      introMessage: new FormControl('', { updateOn: 'blur' })
    });
  }
  async ngOnInit(): Promise<void> {
    await this._http.get(environment.voiceSelectionFile).toPromise().then((voices: any) => {
      this.voiceList = Object.entries(voices);
    });

    await this._http.get(environment.languagePresetFile).toPromise().then((languages: any) => {
      this.languageList = Object.entries(languages);
    });

    const startValues = {
      name: this.npc.label,
      spokenLanguage: this.npc.properties.languageISOCode || this.languageList[0][1],
      selectedVoice: this.npc.properties.voiceId || this.voiceList[0][0],
      introMessage: this.npc.properties.introMessage
    }
    this.characterEditForm.reset(startValues);

    this.characterEditForm.valueChanges.pipe(
      mergeMap(() => from(Object.entries(this.characterEditForm.controls))),
      filter(([label, control]: [string, FormControl]) => control.dirty),
      switchMap(([label, control]: [string, FormControl]) => {
        control.markAsPristine();
        if (label === 'name') {
          return this._courseApiService.updateAnnotation(this.npc.id, { label: control.value });
        } else {
          let path;
          switch (label) {
            case 'spokenLanguage': path = 'properties.languageISOCode'; break;
            case 'selectedVoice': path = 'properties.voiceId'; break;
            case 'introMessage': path = 'properties.introMessage'; break;
          }
          return this._courseApiService.patchAnnotation(this.npc.id, { op: 'replace', path, value: control.value })
        }
      })).subscribe(npc => this.npc = npc);
  }

  enablePreview(): boolean {
    return !!this.characterEditForm.controls.introMessage.value;
  }
  introPreview() {
    const url = `${environment.coursesApiUrl}/api/texttospeech`;
    const body = {
      textValue: this.characterEditForm.controls.introMessage.value,
      voiceOptions: this.voiceList.find(voice => voice[0] === this.npc.properties.voiceId)[1],
      courseId: 'editor-preview',
      isStream: false
    }
    this._http.post(url, body).subscribe((resp: any) => {
      if (!resp.path) return;
      this.audioElm.nativeElement.src = resp.path;
      this.audioElm.nativeElement.play();
    })
  }
}
