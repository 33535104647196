import {
    Component, Input
} from '@angular/core';
import { BreadCrumb } from './breadcrumb.model';
@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})

export class BreadCrumbsComponent {
    @Input()
    breadcrumbs: BreadCrumb[];

    navigate(crumb: BreadCrumb): void {
        if (crumb.action) {
            crumb.action.call(this);
        }
    }
}