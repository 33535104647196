import { AnnotationAction, getDefaultAction } from './annotationAction.model';
const CURRENT_QUIZ_VERSION = 3;
export enum QuizType {
  choices = 'choices',
  input = 'input'
}
export class QuizOutput {
  constructor(
    public feedback_enabled?: boolean,
    public feedback?: string,
    public action?: AnnotationAction

  ) {
  }
}
export function getDefaultChoicesQuiz(): QuizChoicesProperties {
  const quizProperties =
    new QuizChoicesProperties(
      'Edit your question',
      false,
      [
        new QuizAnswer(1, 'Choice 1', null, { ...getDefaultAction(), ...{ scoring_value: 0 } }),
        new QuizAnswer(2, 'Choice 2', null, { ...getDefaultAction(), ...{ scoring_value: 10 } }),
        new QuizAnswer(3, 'Choice 3', null, { ...getDefaultAction(), ...{ scoring_value: -10 } })
      ],
      'Continue',
      'Validate',
      'Restart'
    );
  return quizProperties;
}
export function getDefaultInputQuiz(): QuizInputProperties {
  const quizProperties =
    new QuizInputProperties(
      'Edit your question',
      true,
      null,
      new QuizOutput(true, 'Well done.' , getDefaultAction()),
      new QuizOutput(true, 'Please try again.' , getDefaultAction()),
      'Continue',
      'Validate',
      'Restart'
    );
  return quizProperties;
}
export class QuizInputProperties {
  public quiz_type: QuizType
  public quiz_version: number;
  constructor(
    public question: string,
    public restart_enabled: boolean,
    public accepted_answers?: string[],
    public success_output?: QuizOutput,
    public failure_output?: QuizOutput,
    public continue_label?: string,
    public validate_label?: string,
    public restart_label?: string,
    public is_answered_individually?: boolean
  ) {
    this.quiz_type = QuizType.input;
    this.quiz_version = CURRENT_QUIZ_VERSION;
  }
}
export class QuizChoicesProperties {
  public quiz_type: QuizType;
  public quiz_version: number;
  constructor(
    public question: string,
    public restart_enabled: boolean,
    public answers?: QuizAnswer[],
    public continue_label?: string,
    public validate_label?: string,
    public restart_label?: string,
    public is_answered_individually?: boolean
  ) {
    this.quiz_type = QuizType.choices;
    this.quiz_version = CURRENT_QUIZ_VERSION;
  }
}

export class QuizAnswer {
  public quiz_type: QuizType;
  constructor(
    public id: number,
    public label: string,
    public feedback: string,
    public action: AnnotationAction
  ) {
  }
}
