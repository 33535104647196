import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { LearnerReportComponent } from './learner-report.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { AssessmentReportModule } from '@app/main/analytics/assessment/assessment-report/assessment-report.module';

@NgModule({
    declarations: [
        LearnerReportComponent
    ],
    imports: [
        AssessmentReportModule,
        SharedModule,
        CommonModule,
        NgxEchartsModule.forChild(),
    ],
    exports: [
        LearnerReportComponent
    ]
})
export class LearnerReportModule { }
