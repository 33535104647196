import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Asset } from '@shared/models/asset.model';
import { AssetType } from '@shared/models/assetType.model';
import { Course } from '@shared/models/course.model';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { from } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'simulation-main-settings',
  templateUrl: './simulation-main-settings.component.html',
  styleUrls: ['./simulation-main-settings.component.scss']
})
export class SimulationMainSettingsComponent implements OnInit, OnChanges {
  @Input() course: Course;
  mainSettingsForm: FormGroup;
  introForm: FormGroup;
  AT = AssetType;

  @Output() courseChanged = new EventEmitter<Course>();

  constructor(
    private _courseApiService: CoursesAPIService,
  ) {
    this.mainSettingsForm = new FormGroup({
      title: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)], updateOn: 'blur' }),
      description: new FormControl('', { updateOn: 'blur' }),
    });

    this.introForm = new FormGroup({
      introductionSceneEnabled: new FormControl(false),
      introductionSceneContent: new FormGroup({
        title: new FormControl('', { updateOn: 'blur' }),
        description: new FormControl('', { updateOn: 'blur' }),
        buttonLabel: new FormControl('', { validators: [Validators.required, Validators.maxLength(30)], updateOn: 'blur' })
      })
    });
  }

  ngOnInit(): void {
    this.mainSettingsForm.valueChanges.pipe(
      mergeMap(() => from(Object.entries(this.mainSettingsForm.controls))),
      filter(([label, control]: [string, FormControl]) => control.dirty),
      switchMap(([label, control]: [string, FormControl]) => {
        control.markAsPristine();
        return this._courseApiService.patchCourse(this.course.id, { op: 'replace', path: label, value: control.value })
      })
    ).subscribe((course: Course) => {
      this.courseChanged.emit(course);
    });

    this.introForm.valueChanges.pipe(
      mergeMap(() => from(Object.entries(this.introForm.controls))),
      filter(([label, control]: [string, FormControl]) => control.dirty),
      switchMap(([label, control]: [string, FormControl]) => {
        control.markAsPristine();
        return this._courseApiService.patchCourse(this.course.id, { op: 'replace', path: `information.${label}`, value: control.value })
      })
    ).subscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.course && !!changes.course.currentValue) {
      this.mainSettingsForm.reset({ title: this.course.title, description: this.course.description });
      this.introForm.reset(this.course.information);
      this.toggleForm(this.introForm.controls.introductionSceneEnabled.value);
    }
  }

  toggleForm(enabled: boolean) {
    if (enabled) {
      this.introForm.controls.introductionSceneContent.enable();
    } else {
      this.introForm.controls.introductionSceneContent.disable();
    }
  }

  updatePostermedia(asset: Asset) {
    this._courseApiService
      .updateCourse(this.course.id, { postermedia_id: asset.id }, false)
      .subscribe(course => this.course = course);
  }
}
