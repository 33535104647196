import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { ErrorComponent } from '@app/main/error-page/error.component';

@NgModule({
    declarations: [
        ErrorComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        RouterModule
    ],
    exports: [ErrorComponent]
})
export class ErrorModule { }
