import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Asset } from '@shared/models/asset.model';
import {
  MatLegacyDialog as MatDialog
} from '@angular/material/legacy-dialog';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { AssetType } from '@shared/models';


@Component({
  selector: 'media-picker',
  templateUrl: './media-picker.component.html',
  styleUrls: ['./media-picker.component.scss']
})
export class MediaPickerComponent implements OnChanges {
  @Input() asset: Asset | null;
  @Input() title: string;
  @Input() assetType: AssetType;
  @Input() forceContain: false;
  imagePath: string;

  @Output() selected = new EventEmitter<Asset>()

  constructor(
    private _dialog: MatDialog,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.asset && changes.asset.currentValue) {
      if (this.asset.type !== AssetType.i2D && this.asset.type !== AssetType.i360) {
        this.imagePath = this.asset.thumbnail;
      } else {
        if (this.asset.properties?.variants) {
          const smallestVariant = Object.values(this.asset.properties.variants)[0];
          this.imagePath = smallestVariant.find((variant: any) => variant.format === 'webp').path;
        } else {
          this.imagePath = this.asset.path;
        }
      }
    }
  }

  onOpenFileManager(): void {
    const openOnTab = this.assetType === AssetType.npc ? 3 : 0;
    this._dialog.open(FileManagerComponent, {
      panelClass: 'learnspace-theme',
      data: {
        title: this.title,
        type: this.assetType,
        openOnTab: openOnTab
      },
      id: 'file-manager'
    }).afterClosed()
      .subscribe((newAsset: Asset) => {
        if (!newAsset) return;
        this.selected.emit(newAsset);
        this.asset = newAsset;
      });
  }
}


