import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  Output,
  AfterViewInit
} from '@angular/core';
import {
  AssetType,
  Asset,
  AssetData,
  AssetProviderType
} from '@shared/models';
import {
  UploadFile,
  UploadInput,
  UploaderOptions,
  UploadOutput, NgFileDropDirective
} from 'ngx-uploader';
import { Observable, from } from 'rxjs';
import { UploadService } from '@shared/services/upload.service';
import { takeWhile, switchMap, mergeMap } from 'rxjs/operators';
import { AssetsListComponent } from '@shared/components/file-manager/assets-list/assets-list.component';
import { KalturaService } from '@shared/services/kaltura.service';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { environment } from '@env/environment';
import { FileUtils } from '@gltf-transform/core';
import { GlbService, getBaseName } from '@shared/services/glb.service';
// @ts-ignore
import { generateGlbThumbnail } from '@wondavrspaces/resource';

@Component({
  selector: 'file-drag-drop',
  templateUrl: './file-drag-drop.component.html',
  styleUrls: ['./file-drag-drop.component.scss']
})
export class FileDragDropComponent implements OnInit, AfterViewInit {
  @ViewChild('canvasForThumbnail', { static: false }) canvasRef: ElementRef;
  @ViewChild(NgFileDropDirective) dropDirective: NgFileDropDirective;

  @ViewChild(AssetsListComponent, { static: false })
  assetsList: AssetsListComponent;

  @Input() mode: string;
  @Input() type: AssetType;
  @Input() courseId: string;
  @Input() externalProvider: AssetProviderType;

  @Output() selected = new EventEmitter<Asset>();

  fileIndex = 0;
  filesToUpload: UploadFile[] = [];
  dragOver: boolean;
  uploadState: string; // idle, uploading, finishing
  rejectedFileErrorMsg: string = null;
  media_type: string;
  medias: Asset[] = [];
  acceptedExtensions: string[];
  uploadInput: EventEmitter<UploadInput>;
  options: UploaderOptions;
  progress: Observable<{ name: string; progress: number }>;
  uploadedMsg = 'Your files have been uploaded';
  constructor(
    private _uploadService: UploadService,
    private _kalturaService: KalturaService,
    private _courseService: CoursesAPIService,
    private _glbService: GlbService
  ) {
    this.onFileDrop = this.onFileDrop.bind(this);
  }

  ngOnInit() {
    this.uploadState = 'idle';
    this.uploadInput = new EventEmitter<UploadInput>();
    this.type = this.type as AssetType;
    this.acceptedExtensions = AssetData.get(this.type).mediaExtensions;
    this.options = {
      concurrency: 1,
      allowedContentTypes: this.acceptedExtensions
    };
    this.progress = this._uploadService.progress.asObservable();
    this.progress.pipe(takeWhile(p => p.progress < 0.9)).subscribe(
      () => {
        this.uploadState = 'uploading';
      },
      null,
      () => {
        // Some files remaining in queue
        if (this.filesToUpload && this.fileIndex < this.filesToUpload.length) {
          this.uploadState = 'uploading';
        } else {
          this.uploadState = 'idle';
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.dropDirective.onDrop = this.onFileDrop;
  }

  async onFileDrop(e: DragEvent): Promise<any> {
    const items = Array.from(e.dataTransfer.items).map( function(item: DataTransferItem) {
      return item.webkitGetAsEntry();
    });
    const files: Array<File> = [];
    for (let i = 0; i < items.length; i++) {
      // webkitGetAsEntry is where the magic happens
      const item = items[i];
      if (item) {
        // @ts-ignore
        await traverseFileTree(item, null, files);
      }
    }
    e.stopPropagation();
    e.preventDefault();
    const event = { type: 'drop' };
    // @ts-ignore
    this.dropDirective.uploadOutput.emit(event);
    // @ts-ignore
    this.dropDirective.upload.handleFiles(files);
  }

  isKaltura(): boolean {
    return this.externalProvider === AssetProviderType.kaltura;
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      if (this.rejectedFileErrorMsg === null) {
        if (this.uploadState === 'idle' && this.rejectedFileErrorMsg === null) {
          if (this.externalProvider === AssetProviderType.kaltura) {
            this.kalturaMultipleUpload();
          } else if (this.type === AssetType.r3D || this.type === AssetType.o3D || this.type === AssetType.npc) {
            this.startObject3DUpload(this.type);
          }
          else {
            this.startUpload();
          }
        } else {
          this.rejectFile('Please upload one file at the time', false, 2000);
        }
      }
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      this.filesToUpload.push(output.file);
    } else if (
      output.type === 'uploading' &&
      typeof output.file !== 'undefined'
    ) {
      const index = this.filesToUpload.findIndex(
        file => typeof output.file !== 'undefined' && file.id === output.file.id
      );
      this.filesToUpload[index] = output.file;
    } else if (output.type === 'removed') {
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'rejected') {
      const r = `Please upload a compatible format (${this.acceptedExtensions.map(
        format => format.split('/').pop()
      )})`;
      this.rejectFile(r);
    }
  }

  onCurrentSelect(event: any): void {
    this.selected.emit(event); //just propagate to parent
  }

  private kalturaMultipleUpload() {
    this.fileIndex = 0;
    this.uploadState = 'uploading';
    from(this.filesToUpload)
      .pipe(
        mergeMap(file => {
          return this._kalturaService.startUpload(file, this.type);
        })
      )
      .subscribe(
        next => {
          this.fileIndex++;
        },
        err => console.error(err),
        () => {
          if (this.type === AssetType.v360 || AssetType.v2D) {
            this.uploadedMsg = 'Your video has been uploaded and is being transcoded by Kaltura (this may take a few minutes).';
          }
          this.cleanAfterUpload();
        }
      );
  }
  
    /**
   * Process file : validate format, create asset, upload to storage, create thumbnail 
   * @param file 
   */
  private async processFile(file: File): Promise<void> {
    let metadata;
    if(this.type !== AssetType.sound) {
      metadata  = await this._uploadService.getMediaMetadata(file);
      const formatValidation = this._uploadService.validateUpload(metadata);
      if (!formatValidation) {
        this.rejectFile('Please resize your media (maximum size is 8,640 x 4,320 px)');
        return;
      }
    }
    this.uploadState = 'uploading';
    const asset = new Asset(
      this.type,
      'image desc',
      undefined,
      file.name,
      null
    );
    if(metadata != undefined) {
      asset['properties'] = { originalHeight: metadata.height.toString(), originalWidth: metadata.width.toString(), duration: metadata.duration };
    }

    let updatedFile = file;
    if (this.type === AssetType.i2D || this.type === AssetType.i360) {
      // There are some issue with conversion when converting jpeg to webp in the encoding connector
      // exif metadata which contains rotations are not correctly saved and parsed when converting to webp causing some image to be rotated
      // if we have an image, we are drawing this image with interpreted rotation by the browser and saving this copy as the source
      updatedFile = await this._uploadService.redrawImage(file, this.canvasRef.nativeElement);
    }

    const a = await this._uploadService
      .createAndUploadAsset(asset, updatedFile, this.courseId)
      .toPromise();
    if (a.type !== AssetType.sound) {
      await this._uploadService.generateThumbnail(
        updatedFile,
        a,
        this.canvasRef,
        this.thumbCallback
      );
    } else {
      this.assetsList.loadAssets();
    }
  }

  private async startUpload(): Promise<void> {
    for (let i = 0; i < this.filesToUpload.length; i++) {
      this._uploadService.unloadLocalMedia();
      this.fileIndex = i;
      const file = this.filesToUpload[i].nativeFile;
      await this.processFile(file);
    }
    this.cleanAfterUpload();
  }

  private async startObject3DUpload(type: AssetType) {
    const { source, thumbnail } = this.findMandatoryObject3DFiles();
    if (!source) {
      this.rejectFile('Cannot upload room, GLB or GLTF or ZIP file is missing', true);
      return;
    }
    let object3DPackage;
    try {
      object3DPackage = await this.prepareObject3DPackage(source);
    } catch (error) {
      this.rejectFile('Cannot upload room, error while trying to handle the file', true);
      return;
    }
    if (!object3DPackage) {
      this.rejectFile('Cannot upload room, error while trying to handle the file', true);
      return;
    }

    const { assetName, bundleName, glbFile, jsonBundleFile } = object3DPackage;
    const asset = new Asset(
      type,
      'image desc',
      undefined,
      assetName,
      null,
      null,
      null,
      null,
      {},
      false
    );

    // Processing thumbnail
    let thumbnailToOptimize = thumbnail && thumbnail.nativeFile;
    if (!thumbnailToOptimize) {
      thumbnailToOptimize = await generateGlbThumbnail(glbFile);
    }
    this.uploadState = 'uploading';
    const createdAsset = await this._courseService.createMedia(asset).toPromise();
    await this._uploadService.optimizeThumbnail(
      thumbnailToOptimize,
      createdAsset,
      this.canvasRef.nativeElement,
    );
    // Warning : it is much better to use UploadFile object to handle progress and identify files but in that case it is not possible because of the create json file
    const toUploadFiles = [ glbFile, jsonBundleFile ];
    for (let i = 0; i < toUploadFiles.length; i++) {
      await this._uploadService.uploadFile(toUploadFiles[i], `/content/${createdAsset.id}/${toUploadFiles[i].name}`).toPromise();
    }
    await this._courseService.partialUpdateMedia(createdAsset.id, {
      path: `${environment.contentBucketPrefix}/content/${createdAsset.id}/${bundleName}`
    }).toPromise();
    this.cleanAfterUpload();
  }

  private thumbCallback = () => {
    this.assetsList.loadAssets();
  }

  /** For 3D room */
  private findMandatoryObject3DFiles(): any {
    const fileToUpload: UploadFile = this.filesToUpload.find((file) => ['gltf', 'glb', 'zip'].indexOf(FileUtils.extension(file.name)) !== -1);
    const imageMimeType = AssetData.get(AssetType.i2D).mediaExtensions;
    const thumbnail = this.filesToUpload.find(file => {
        return file.name.includes('thumbnail') && imageMimeType.find(type => type === file.type) !== undefined;
    });
    return {
      source: fileToUpload,
      thumbnail: thumbnail,
    };
  }

  private async prepareObject3DPackage(fileToUpload: UploadFile) {
    const assetName = getBaseName(fileToUpload.name);
    let glbFile;
    switch (FileUtils.extension(fileToUpload.name)) {
      case 'zip':
        glbFile = await this._glbService.zipToGlb(fileToUpload.nativeFile);
        break;
      case 'glb':
        glbFile = fileToUpload.nativeFile;
        break;
      case 'gltf':
        glbFile = await this._glbService.gltfToGlb(this.filesToUpload.map(file => file.nativeFile));
        break;
    }
    const jsonBundle = this.generateJSONBundle(assetName, glbFile.name);
    const finalGlb = this.setGLTFMimeType(glbFile);

    return {
      assetName: assetName,
      bundleName: jsonBundle.filename,
      glbFile: finalGlb,
      jsonBundleFile: jsonBundle.file
    };
  }

  private setGLTFMimeType(file: File): File {
    let mimeType;
    if (FileUtils.extension(file.name) === 'gltf') {
      mimeType = 'model/gltf+json';
    } else {
      mimeType = 'model/gltf-binary';
    }
    const blob = new Blob([file], { type: mimeType });
    return new File([blob], file.name, { type: mimeType });
  }

  // Generate bundle JSON file that will be loaded by the classroom
  private generateJSONBundle(name: string, src: string): { file: File; filename: string } {
    const bundleObject = {
      name: name,
      meta: {
        title: name
      },
      assets: [
        {
          name: name,
          src: src
        }
      ]
    };
    const st = JSON.stringify(bundleObject);

    const blob = new Blob([st], { type: 'application/json' });
    const filename = name.split(' ').join('_').split('%20').join('_') + '.bundle.json';
    return {
      file: new File([blob], filename, { type: 'application/json' }),
      filename: filename
    };
  }

  private cleanAfterUpload() {
    this.uploadState = 'finishing';
    this.filesToUpload = [];
    this.assetsList.loadAssets();
    setTimeout(() => {
      this.uploadState = 'idle';
    }, 2000);
  }

  private rejectFile(reason: string, cleanList?: boolean, duration?: number) {
    const d = duration || 4000;
    this.rejectedFileErrorMsg = reason;
    if (cleanList) {
      this.filesToUpload = [];
    }
    setTimeout(() => {
      this.rejectedFileErrorMsg = null;
    }, d);
  }
}

async function traverseFileTree(item: any, path: string, files: Array<File>): Promise<any> {
  path = path || '';
  if (item.isFile) {
    // Get file
    const file = await fileAsPromise(item);
    files.push(new File([file], path + file.name, { type: file.type }));
  } else if (item.isDirectory) {
    // Get folder contents
    const entries = await readDirEntries(item);
    const dirReadingPromiseA = [];
    for (let i = 0; i < entries.length; i++) {
      dirReadingPromiseA.push(await traverseFileTree(entries[i], path + item.name + '/', files));
    }
    return await Promise.all(dirReadingPromiseA);
  }
}
async function fileAsPromise(entry: any): Promise<File>{
  return new Promise((resolve, reject) => {
    entry.file(resolve, reject);
  });
}

async function readDirEntries(dirItem: any): Promise<any> {
  const dirReader = dirItem.createReader();
  return new Promise((resolve) => {
    dirReader.readEntries(resolve);
  });
}
