<div>
  <ng-container *ngIf="asset">
    <img [src]="imagePath" [ngClass]="{'force-contain': forceContain === true}">
    <div class="asset-name">
      <span>{{asset.name}}</span>
    </div>
  </ng-container>
  <button mat-stroked-button color="primary" (click)="onOpenFileManager()">
    <mat-icon fontSet="fa" fontIcon="fa-pen"></mat-icon>
    CHOOSE FROM LIBRARY
  </button>
</div>