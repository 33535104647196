import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { TypeSelectorComponent } from '@shared/components/type-selector/type-selector.component';
import { UploadService } from '@shared/services/upload.service';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { SupervisorService } from '@shared/services/supervisor.service';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { RoomSelectorComponent } from '@shared/components/room-selector/room-selector.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@shared/material.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FileDropperComponent } from '@shared/components/file-dropper/file-dropper.component';
import { InternalAnalyticsService } from '@shared/services/internalAnalytics.service';
import { httpInterceptorProviders } from '@app/http-interceptors';
import { CurrentContextService } from '@shared/services/currentContextService';
import { KalturaService } from '@shared/services/kaltura.service';
import { AssetsListComponent } from '@shared/components/file-manager/assets-list/assets-list.component';
import { YoutubeSelectorComponent } from '@shared/components/file-manager/youtube-selector/youtube-selector.component';
import { YoutubeService } from '@shared/services/youtube.service';
import { DeviceService } from '@shared/services/device.service';
import { DurationPickerComponent } from '@shared/components/duration-picker/duration-picker.component';
import { AddParticipantDialogComponent } from '@shared/components/add-participant-dialog/add-participant-dialog.component';
import { TagInputModule } from 'ngx-chips';
import { IBMSelectorComponent } from '@shared/components/file-manager/ibm-selector/ibm-selector.component';
import { IBMService } from '@shared/services/ibm.service';
import { SceneSelectorComponent } from '@shared/components/scene-selector/scene-selector.component';
import { AnnotationSelectorComponent } from '@shared/components/annotation-selector/annotation-selector.component';
import { SendInvitationComponent } from '@shared/components/send-invitation/send-invitation.component';
import { FileDragDropComponent } from '@shared/components/file-manager/file-drag-drop/file-drag-drop.component';
import { InteractiveThumbnailComponent } from '@shared/components/interactive-thumbnail/interactive-thumbnail.component';
import { LinkService } from '@shared/services/link.service';
import { GlbService } from '@shared/services/glb.service';
import { PlanLimitDirective } from '@shared/directives/plan-limit.directive';
import { DurationConverter } from '@app/main/analytics/misc/duration-converter.pipe';
import { AnalyticsService } from '@shared/services/analytics.service';
import { ExperienceSelectorComponent } from '@shared/components/experience-selector/experience-selector.component';
import { BreadCrumbsComponent } from '@shared/components/breadcrumbs/breadcrumbs.component';
import { AddAnnotationMenuComponent } from '@app/main/apps/forms/add-annotation-menu/add-annotation-menu.component';
import { Object3DPerformanceComponent } from '@shared/components/object3D-performance/object3d-performance.component';
import { UndoRedoComponent } from '@shared/components/undo-redo/undo-redo.component';
import { UndoRedoShortcutDirective } from '@shared/directives/shortcuts/undo-redo-shortcut-directive';
import { DeleteShortcutDirective } from '@shared/directives/shortcuts/delete-shortcut-directive';
import { CopyPasteShortcutDirective } from '@shared/directives/shortcuts/copy-paste-shortcut-directive';
import { BrowserShortcutDisabler } from '@shared/directives/shortcuts/browser-shortcut-disabler';
import { RelativeDatePipe } from '@shared/pipes/relative-date.pipe';
import { LiveLinkComponent } from '@shared/components/file-manager/live-link/live-link.component';
import { ReadyPlayerMeLinkComponent } from '@shared/components/file-manager/ready-player-me-link/ready-player-me-link.component';
import { AssessmentService } from '@shared/services/assessment.service';
import { MediaPickerComponent } from './components/media-picker/media-picker.component';
import { EditableDrawerComponent } from './components/editable-drawer/editable-drawer.component';
import { CourseLabelPipe, SpaceLabelPipe, ParticipantPipe } from './pipes/wording.pipe';

@NgModule({
    declarations: [
        AddAnnotationMenuComponent,
        AddParticipantDialogComponent,
        AnnotationSelectorComponent,
        AssetsListComponent,
        BreadCrumbsComponent,
        BrowserShortcutDisabler,
        ConfirmationDialogComponent,
        CopyPasteShortcutDirective,
        DeleteShortcutDirective,
        DurationConverter,
        DurationPickerComponent,
        ExperienceSelectorComponent,
        FileDropperComponent,
        FileManagerComponent,
        FileDragDropComponent,
        IBMSelectorComponent,
        LiveLinkComponent,
        ReadyPlayerMeLinkComponent,
        InteractiveThumbnailComponent,
        Object3DPerformanceComponent,
        PlanLimitDirective,
        RelativeDatePipe,
        SpaceLabelPipe,
        CourseLabelPipe,
        ParticipantPipe,
        RoomSelectorComponent,
        SceneSelectorComponent,
        SendInvitationComponent,
        TypeSelectorComponent,
        UndoRedoComponent,
        UndoRedoShortcutDirective,
        YoutubeSelectorComponent,
        MediaPickerComponent,
        EditableDrawerComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        HttpClientModule,
        TagInputModule,
        NgxUploaderModule,
        FlexLayoutModule,
        FormsModule
    ],
    providers: [
        CoursesAPIService,
        CurrentContextService,
        DeviceService,
        InternalAnalyticsService,
        KalturaService,
        UploadService,
        SupervisorService,
        httpInterceptorProviders,
        YoutubeService,
        IBMService,
        LinkService,
        AnalyticsService,
        AssessmentService,
        GlbService
    ],
    exports: [
        AddAnnotationMenuComponent,
        TagInputModule,
        TypeSelectorComponent,
        FileDropperComponent,
        FileManagerComponent,
        ConfirmationDialogComponent,
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxUploaderModule,
        FlexLayoutModule,
        FormsModule,
        RoomSelectorComponent,
        DurationPickerComponent,
        AddParticipantDialogComponent,
        SceneSelectorComponent,
        SendInvitationComponent,
        InteractiveThumbnailComponent,
        PlanLimitDirective,
        Object3DPerformanceComponent,
        DurationConverter,
        ExperienceSelectorComponent,
        BreadCrumbsComponent,
        AnnotationSelectorComponent,
        UndoRedoComponent,
        UndoRedoShortcutDirective,
        DeleteShortcutDirective,
        CopyPasteShortcutDirective,
        BrowserShortcutDisabler,
        RelativeDatePipe,
        SpaceLabelPipe,
        CourseLabelPipe,
        ParticipantPipe,
        MediaPickerComponent,
        EditableDrawerComponent
    ]
})
export class SharedModule { }
