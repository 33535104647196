<mat-expansion-panel [expanded]="shouldExpand" (expandedChange)="onExpandedChange($event)">
  <mat-expansion-panel-header>
    <mat-panel-title (keydown)="disableKeyboardToggle($event)" (click)="onTitleClick($event)">
      <mat-form-field [appearance]="titleAppearance" floatLabel="always" class="title-input">
        <mat-label *ngIf="isExpanded">Title</mat-label>
        <span *ngIf="showIndex" matPrefix class="index-prefix bold-text">{{ index + 1 }} - </span>
        <input matInput [(ngModel)]="content.title" class="bold-text" (change)="onChange()">
      </mat-form-field>
      <div class="action-icon-wrapper">
        <mat-icon *ngIf="showDelete()" (click)="onDeleteClick($event)" fontSet="fa" fontIcon="fa-trash"
          class="action-icon"></mat-icon>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field appearance="outline" class="description-form">
    <mat-label>Description</mat-label>
    <textarea matInput [(ngModel)]="content.description" (change)="onChange()"></textarea>
  </mat-form-field>
</mat-expansion-panel>