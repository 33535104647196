import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { MetahomeComponent } from '@app/main/metahome/metahome.component';
import { ExperienceCardComponent } from '@app/main/metahome/experience-card/experience-card.component';
import { LoginModule } from '@app/main/login/login.module';

@NgModule({
    declarations: [
        MetahomeComponent,
        ExperienceCardComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        LoginModule
    ],
    exports: [MetahomeComponent]
})
export class MetahomeModule { }
