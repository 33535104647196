import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset, AssetType, AssetProviderType } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { CurrentHubService } from '@shared/services/currentHub.service';
import { HttpClient } from '@angular/common/http';
// import { UploadService } from '@shared/services/upload.service';
@Component({
  selector: 'live-link',
  templateUrl: './live-link.component.html',
  styleUrls: ['./live-link.component.scss', '../file-manager.component.scss']
})
export class LiveLinkComponent implements OnInit {
  @Input() type: AssetType;
  @Output() selected = new EventEmitter<Asset>();
  currentVideo: Asset;
  pathError: Error;
  constructor(
    private _courseService: CoursesAPIService,
    // private _uploadService: UploadService,
    private _http: HttpClient
  ) { }
  ngOnInit(): void {}
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    this.validatePathAndCreateMedia(pastedText);
  }

  async validatePathAndCreateMedia(path: string): Promise<void> {
    this.pathError = undefined;
    // path = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
    const name = path.substring(path.lastIndexOf('/') + 1);
    //const extension = name.split('.').pop();
    try {
      // if(extension !== 'm3u8' && extension !== 'mpd') {
      //   throw new Error('Invalid format');
      // }
      try {
        await this._http.get(path, { responseType: 'text' }).toPromise();
      } catch (error) {
        console.error(error);
        throw new Error('Invalid path');
      }
      
      const media = new Asset(
        this.type,
        'no desc',
        null,
        name || 'live media',
        null,
        path,
        null,
        null, //Thumbnail path
        { isLive: true },
        false,
        AssetProviderType.wonda
      );
      this.currentVideo = media;
    } catch (error) {
      this.pathError = error;
    }
  }

  async selectVideo(asset: Asset): Promise<void> {
    const a = await this._courseService.createMedia(asset).toPromise();
    // TODO generate thumbnail
    this.selected.emit(a);
  }

  goToHelp() {
    window.open('https://help.spaces.wondavr.com/en/articles/7044007-video-from-live-source');
  }
 }
