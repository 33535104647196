import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { Observable, of } from 'rxjs';
import { Invitation, InvitationType, Learnspace, Hub, HubMode, LearnspaceMode } from '@shared/models';
import { AuthenticationService } from '@shared/services/authentication.service';
import { spaceLabel, StringCase } from '@shared/utils/wording.utils';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {

  invitation: Observable<Invitation>;
  target: Learnspace | Hub;
  errorMessage: string;
  isAuthenticated: boolean;
  mode?: HubMode | LearnspaceMode;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _courseApiService: CoursesAPIService,
    private _authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this._authService.isAuth().then(isAuth => this.isAuthenticated = isAuth); 
    this.invitation = this._route.paramMap.pipe(
      map(param => param.get('id')),
      switchMap(id => this._courseApiService.getInvitation(id)),
      tap(invit => {
        if (invit.type === InvitationType.joinHub) {
          this.target = invit.hub;
          this.mode = invit.hub?.mode;
        } else {
          this.target = invit.learnspace;
          this.mode = invit.learnspace?.mode;
        }
        // Set a url after redirect 
        this._authService.invitation = invit;
        this._authService.redirectURL = this._router.url;
      }),
      catchError((e) => {
        if (e.status === 410) {
          this.errorMessage = 'This invitation has expired';
        } else {
          this.errorMessage = 'Invalid invitation link';
        }
        return of(null);
     }),
    ); 
  }

  readableInvitationType(type: InvitationType): string {
    switch (type) {
      case InvitationType.joinHub:
        return 'Hub';
      case InvitationType.joinLearnspace:
        return spaceLabel(this.mode, false, StringCase.titlecase);
    }
  }

  acceptInvitation(invit: Invitation): void {
    this._courseApiService.activateInvitation(invit.id).subscribe(
      val => {
        if (invit.type === InvitationType.joinHub) {
          this._router.navigate([val.hub.urlTitle]);
        } else {
          this._router.navigate([val.learnspace.hub.urlTitle, val.target_id]);
        }
      }
    );
  }
}
