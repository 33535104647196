import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

interface DrawerContent {
  title: string,
  description: string
}

@Component({
  selector: 'editable-drawer',
  templateUrl: './editable-drawer.component.html',
  styleUrls: ['./editable-drawer.component.scss']
})

export class EditableDrawerComponent implements OnChanges {
  @ViewChild('titleInput', { static: false })
  public titleInputElm: ElementRef;

  @Input() content: DrawerContent;
  @Input() index: number;
  @Input() canDelete = true;
  @Input() showIndex = false;
  @Output() changed = new EventEmitter<{ data: DrawerContent, index: number }>();
  @Output() deleted = new EventEmitter<number>()
  isExpanded: boolean;
  shouldExpand = false;
  titleAppearance = 'none';

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.content.description || this.content.description == '') {
      this.shouldExpand = true;
    }
  }
  disableKeyboardToggle(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.stopImmediatePropagation();
    }
  }

  showDelete() {
    return this.canDelete && this.isExpanded;
  }

  onChange() {
    this.changed.emit({ data: this.content, index: this.index });
  }

  onEditClick(event: Event) {
    this.titleInputElm.nativeElement.select();
    event.stopPropagation();
  }

  onExpandedChange(event: boolean) {
    this.isExpanded = event;
    if(this.isExpanded) {
      this.titleAppearance = 'fill';
    } else {
      this.titleAppearance = 'none';
    }
  }
  
  onDeleteClick(event: Event) {
    this.deleted.emit(this.index);
    event.stopPropagation();
  }

  onTitleClick(event: any) {
    if(this.isExpanded) {
      event.stopPropagation();
    }
  }
}
