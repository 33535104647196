import { Component, OnInit, Input } from '@angular/core';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { Learnspace, Hub, User } from '@shared/models';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-manage-user-access',
  templateUrl: './manage-user-access.component.html',
  styleUrls: ['./manage-user-access.component.scss']
})
export class ManageUserAccessComponent implements OnInit {
  @Input() user: User;
  @Input() hub: Hub;

  userLsList: Learnspace[] = [];
  role: string;
  changes: any = {};
  constructor(
    private _courseService: CoursesAPIService,
    private _overlayContainer: OverlayContainer,
    public dialogRef: MatDialogRef<ManageUserAccessComponent>,
  ) { 
    this._overlayContainer.getContainerElement().classList.add('learnspace-theme');
    this._overlayContainer.getContainerElement().classList.remove('editor-theme');
  }

  ngOnInit(): void {
    this.role = this.user.HubsUsers.role;
    this._courseService.getAssocitatedLearnspacesByUserIdByHubId(this.user.id, this.hub.id)
      .subscribe(lsList => this.userLsList = lsList);
  }

  isAdmin(): boolean {
    return this.role === 'admin';
  }
  isInUserList(learnspaceId: string): boolean {
    return this.userLsList.find(ls => ls.id === learnspaceId) !== undefined;
  }

  addToList(learnspaceId: string, event: MatCheckboxChange): void {
    this.changes[learnspaceId] = event.checked;
  }

  commitChanges(): void {
    let subscribeTo, unsubscribeTo;
    if (!this.isAdmin()) {
      subscribeTo = Object.keys(this.changes).filter(c => this.changes[c] === true);
      unsubscribeTo = Object.keys(this.changes).filter(c => this.changes[c] === false);
    }
    this._courseService.associateUserToHub(
      this.hub.id,
      this.user.id,
      this.role,
      subscribeTo,
      unsubscribeTo
    ).subscribe(() => { this.dialogRef.close(); });
  }

  close(): void {
    this.dialogRef.close(this.role);
  }
}
