import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SimulationEditComponent } from './simulation-edit.component';
import { SimulationMainSettingsComponent } from './simulation-main-settings/simulation-main-settings.component';
import { SharedModule } from '@shared/shared.module';
import { HubResolver } from '@app/hub-resolver/hub-resolver';
import { CharacterEditComponent } from './character-edit/character-edit.component';
import { CharacterPromptComponent } from './character-prompt/character-prompt.component';
import { AssessmentEditComponent } from './assessment-edit/assessment-edit.component';
import { AssessmentEditDialogModule } from '@app/main/apps/forms/assessment/assessment-edit-dialog.module';
import { AuthGuard } from '@app/guards/auth.guard';


@NgModule({
  declarations: [
    SimulationEditComponent,
    SimulationMainSettingsComponent,
    CharacterEditComponent,
    CharacterPromptComponent,
    AssessmentEditComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot([
      {
        path: ':urlTitle/:lsId/simulations/:courseId',
        component: SimulationEditComponent,
        resolve: { hub: HubResolver },
        canActivate: [AuthGuard]
      }
    ]),
    SharedModule,
    AssessmentEditDialogModule
  ]
})
export class SimulationEditModule { }
