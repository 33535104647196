<mat-tab-group>
  <mat-tab label="CHARACTER PROMPTS">
    <ng-template matTabContent>
      <ng-container *ngFor="let category of promptCategories; index as i; trackBy:trackByIndex">
        <editable-drawer [content]="category" [index]="i" (changed)="categoryChanged($event)"
          (deleted)="removeCategory($event)"></editable-drawer>
      </ng-container>
      <div class="add-category">
        <mat-form-field class="add-category-title">
          <mat-label>Category title</mat-label>
          <input matInput [(ngModel)]="newCategoryTitle">
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="addCategory()">
          <mat-icon matPrefix fontSet="fa" fontIcon="fa-plus-circle"></mat-icon>
          ADD CATEGORY
        </button>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="PLAIN TEXT">
    <form [formGroup]="plainTextForm">
      <mat-form-field class="plain-text">
        <mat-label>Simulation prompt</mat-label>
        <textarea matInput formControlName="prompt"></textarea>
      </mat-form-field>
    </form>
  </mat-tab>
</mat-tab-group>