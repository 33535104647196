<mat-tab-group *ngIf="course">
  <!-- general -->
  <mat-tab label="MAIN INFORMATION">
    <form [formGroup]="mainSettingsForm" class="main-settings-form">
      <mat-form-field>
        <mat-label>Simulation name</mat-label>
        <input matInput formControlName="title" maxlength="50" #titleInput>
        <mat-hint align="end">{{titleInput.value?.length || 0}}/50</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput formControlName="description">
      </mat-form-field>
    </form>
    <media-picker [asset]="course.postermedia" [assetType]="AT.i2D" [title]="'Select or add a poster'" (selected)="updatePostermedia($event)"></media-picker>
  </mat-tab>

  <!-- intro scene -->
  <mat-tab label="INTRODUCTION (OPTIONAL)">
    <ng-template matTabContent>
      <form [formGroup]="introForm">
        <mat-checkbox formControlName="introductionSceneEnabled" (change)="toggleForm($event.checked)" color="primary">Enable
          introduction scene</mat-checkbox>
        <ng-container formGroupName="introductionSceneContent" class="intro-scene-content">
          <mat-form-field class="wide-text-form">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title">
          </mat-form-field>
          <mat-form-field class="wide-text-form">
            <mat-label>Content</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>
          <mat-form-field class="wide-text-form">
            <mat-label>Button</mat-label>
            <input matInput formControlName="buttonLabel" #buttonLabel>
            <mat-hint align="end">{{buttonLabel.value?.length || 0}}/30</mat-hint>
          </mat-form-field>
        </ng-container>

      </form>
    </ng-template>
  </mat-tab>
</mat-tab-group>