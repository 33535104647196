import { Component, Input, OnChanges, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '@shared/services/authentication.service';
import { KalturaService } from '@shared/services/kaltura.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Observable, zip } from 'rxjs';


@Component({
  selector: 'kaltura-account-form',
  templateUrl: './kaltura-account-form.component.html',
  styleUrls: ['./kaltura-account-form.component.scss']
})
export class KalturaAccountFormComponent implements OnChanges {
  @Input() hubId: string;
  @Output() connectionChange = new EventEmitter<void>();
  kalturaAccountForm: FormGroup;
  clientFeedback: 'ok' | 'ko';
  serverFeedback: 'ok' | 'ko' | 'insufficient rights';

  constructor(
    private _kalturaService: KalturaService,
    private _courseApiService: CoursesAPIService,
    private _authService: AuthenticationService,
  ) {
    this.kalturaAccountForm = new FormGroup({
      clientAppToken: new FormControl('', { validators: Validators.required }),
      serverAppToken: new FormControl('', { validators: Validators.required }),
      kalturaIdMethod: new FormControl('')
    });
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.hubId) {
      this._courseApiService.getHubIntegrations(this.hubId).subscribe(creds => {
        // Default value for kalturaIdMethod if unset
        if (creds.kaltura) {
          const kalturaIdMethod = (creds.kaltura.idMethod) ? creds.kaltura.idMethod : 'email';
          this.kalturaAccountForm.reset({ clientAppToken: JSON.stringify(creds.kaltura.client), serverAppToken: JSON.stringify(creds.kaltura.server), kalturaIdMethod: kalturaIdMethod });
        }
      });

      this.checkClientAppToken().subscribe();
      this.checkServerAppToken().subscribe();
    }
  }

  isConnected(): boolean {
    return this.serverFeedback === 'ok' && this.clientFeedback === 'ok';
  }

  isNotConnected(): boolean {
    return this.serverFeedback === 'ko' || this.clientFeedback === 'ko';
  }
  
  checkToken(value: string) {
    try {
      return JSON.parse(this.kalturaAccountForm.value[value]);
    } catch (error) {
      console.warn('JSON parsing error', error);
      this.kalturaAccountForm.controls[value].setValue('{}');
    }
  }

  updateKalturaAccount(): void {
    let credentials: any = {};
    const clientAppToken = this.checkToken('clientAppToken');
    const serverAppToken = this.checkToken('serverAppToken');
    credentials = { server: serverAppToken, client: clientAppToken, idMethod: this.kalturaAccountForm.value.kalturaIdMethod };
    this._courseApiService
      .patchHubIntegrations(this.hubId, { op: 'set', values: { kaltura: credentials } })
      .pipe(
        switchMap(() => {
          this.connectionChange.emit();
          return zip(this.checkClientAppToken(), this.checkServerAppToken());
        })
      )
      .subscribe();
  }

  checkClientAppToken(): Observable< string> {
    return this._kalturaService.startKalturaSession(this.hubId, this._authService.wondaUser).pipe(
      map(() => {
        this.clientFeedback = 'ok';
        return 'ok';
      }),
      catchError(err => {
        console.error(err);
        this.clientFeedback = 'ko';
        return 'ko';
      })
    )
  }

  checkServerAppToken(): Observable<string> {
    return this._kalturaService.checkBackendConfig(this.hubId).pipe(
      map((resp) => {
        this.serverFeedback = resp.backSession;
        return resp.backSession;
      }),
      catchError(err => {
        console.error(err);
        this.serverFeedback = 'ko';
        return 'ko';
      })
    )
  }
}
