<div fxLayout="column" fxLayoutAlign="space-evenly center" class="wrapper learnspace-theme">
  <div class="invitation-content" fxLayout="column" fxLayoutAlign="center center">
    <ng-container *ngIf="invitation | async as invit">
      <ng-container *ngIf="isAuthenticated; else notAuth">
        <h3>Welcome {{ _authService.wondaUser.first_name }}!</h3>
        <ng-container *ngIf="invit.type !== 'requestaccess_learnspace'; else acceptRequest">
          <p align="center">Thank you for joining
            <strong>{{ target.title | uppercase }}</strong> {{ readableInvitationType(invit.type) }}
            <br />
            <ng-container *ngIf="invit.options?.role === 'admin'">
              <br/>as an administrator
            </ng-container>
          </p>
          <button mat-raised-button color="primary" (click)="acceptInvitation(invit)">Enter {{ readableInvitationType(invit.type) }}</button>
        </ng-container>
        <ng-template #acceptRequest>
          <p align="center">{{ invit.sender.first_name }} has requested access to the {{ mode | spaceLabel }}
            <strong>{{ target.title | uppercase }}</strong>
            <br />
          </p>
          <button mat-raised-button color="primary" (click)="acceptInvitation(invit)">Accept Request</button>
        </ng-template>

      </ng-container>
      <ng-template #notAuth>
        <h3>Hey there!</h3>
        <ng-container *ngIf="invit.type !== 'requestaccess_learnspace'; else acceptRequestNoAuth">
          <p align="center">You've been invited to join
            <br />
            <strong>{{ target.title | uppercase }}</strong> {{ readableInvitationType(invit.type) }}
            <ng-container *ngIf="invit.options?.role === 'admin'">
              <br/>as an administrator
            </ng-container>
          </p>
        </ng-container>
        <ng-template #acceptRequestNoAuth>
          <p align="center">{{ invit.sender.first_name }} has requested access to:
            <br />
            <strong>{{ target.title | uppercase }}</strong> {{ readableInvitationType(invit.type) }}
          </p>
        </ng-template>
        <div class="authButtons">
          <p align="center"> Create an account or login on Wonda:</p>
          <button mat-stroked-button color="primary" [routerLink]="['/login']"> LOG IN </button>
          <button mat-raised-button color="primary" [routerLink]="['/signup']"> REGISTER </button>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="errorMessage">
      {{ errorMessage }}
    </ng-container>
  </div>
</div>