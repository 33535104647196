import { NgModule } from '@angular/core';
import { SimulationEditModule } from './simulation-edit/simulation-edit.module';

@NgModule({
  declarations: [
  ],
  imports: [
    SimulationEditModule
  ]
})
export class ProgramModule { }
