import { Pipe, PipeTransform } from '@angular/core';
import { ExperienceType, HubMode, LearnspaceMode } from '@shared/models';
import { courseLabel, participantLabel, spaceLabel, StringCase } from '@shared/utils/wording.utils';

@Pipe({ name: 'spaceLabel' })
export class SpaceLabelPipe implements PipeTransform {
  transform(spaceOrMode: HubMode | LearnspaceMode | undefined, plural = false, format: StringCase = StringCase.lowercase): string {
    return spaceLabel(spaceOrMode, plural, format)
  }
}

@Pipe({ name: 'courseLabel' })
export class CourseLabelPipe implements PipeTransform {
  transform(spaceOrMode: LearnspaceMode | ExperienceType | undefined, plural = false, format: StringCase = StringCase.lowercase): string {
    return courseLabel(spaceOrMode, plural, format)
  }
}

@Pipe({ name: 'participantLabel' })
export class ParticipantPipe implements PipeTransform {
  transform(spaceOrMode: LearnspaceMode | undefined, plural = false, format: StringCase = StringCase.lowercase): string {
    return participantLabel(spaceOrMode, plural, format)
  }
}
