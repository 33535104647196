import { AssetType } from '@shared/models/assetType.model';
import { AssetProviderType } from '@shared/models/providerType.model';

export class Asset {
  public createdAt: string;
  public updatedAt: string;
    constructor(
        public type: AssetType,
        public description: string,
        public id?: string,
        public name?: string,
        public user_id?: string,
        public path?: string,
        public state?: string, // TODO enum
        public thumbnail?: string,
        public properties?: {
          variants?: any[];
          gender?: string,
          isVOD?: boolean,
          isLive?: boolean,
          originalWidth?: string,
          originalHeight?: string,
          duration?: number,
          ibmId?: string,
          ibmEndPoint?: string
        },
        public isPublic?: boolean,
        public provider?: AssetProviderType        
    ) { }
}
