import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { User } from '@shared/models/user.model';


@Component({
    selector: 'account-delete-confirmation',
    templateUrl: './account-delete-confirmation-dialog.component.html',
    styleUrls: ['./account-delete-confirmation-dialog.component.scss']
})
export class AccountDeleteConfirmationComponent implements OnInit {
    wondaUser: User;

    constructor(
        public _dialogRef: MatDialogRef<AccountDeleteConfirmationComponent>,
        private _authService: AuthenticationService,
        private _courseApiService: CoursesAPIService,
        @Inject(MAT_DIALOG_DATA) public _data: { title: string, type: string }
    ) { }

    ngOnInit(): void {
        this.wondaUser = this._authService.wondaUser;
    }

    onDelete(): void {
        this._courseApiService.deleteMyUser()
            .subscribe(() => {
                this._authService.logout();
                this._dialogRef.close();
            });
    }

    onClose(): void {
        return this._dialogRef.close();
    }
}

