import { NgModule } from '@angular/core';
import { CreateHubComponent } from '@app/main/hub/create-hub/create-hub.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { ManageUserAccessComponent } from '@app/main/hub/manage-user-access/manage-user-access.component';
import { LTICredentialsPlatformSettingsComponent } from '@app/main/settings/third-party-integrations/lti-credentials/platfom-settings/lti-credentials-platfom-settings.component';
import { AuthGuard } from '@app/guards/auth.guard';

const routes = [
  {
    path: 'createProgramHub',
    component: CreateHubComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'createHub', // Space hubs
    component: CreateHubComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
    declarations: [CreateHubComponent, ManageUserAccessComponent, LTICredentialsPlatformSettingsComponent],
    imports: [
        RouterModule.forChild(routes),
        SharedModule
    ]
})
export class HubModule { }
