import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { AssessmentReportComponent } from './assessment-report.component';

@NgModule({
    declarations: [
        AssessmentReportComponent,
    ],
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        NgxEchartsModule.forChild(),
    ],
    exports: [
        AssessmentReportComponent,
    ]
})
export class AssessmentReportModule { }
