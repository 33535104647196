<div *ngIf="user" id="user-menu">
  <div class="logo">
    <img (click)="goToRoot()" src="assets/wonda/Logo_Wonda_W_icon.png" alt="logo" height="27px" matTooltip="Back to all Spaces" matTooltipShowDelay="500">
  </div>
  <div class="toptext">
    <div *ngIf="( hubObs | async )?.plan && isFreePlan() ; else paid">
        You are using the {{ ( hubObs | async )?.plan }} plan,
        <span class="sales-button" (click)="openPlanDoc()">learn more</span>.
    </div>
    <ng-template #paid>
        <div *ngIf="( hubObs | async )?.plan && getDaysLeft() <= 60">
          Your current plan is set to renew in {{ getDaysLeft() }} days. 
          <span class="sales-button" (click)="contactSales()">Learn more</span>.
        </div>
    </ng-template>
  </div>
  <div class="end">
    <div class="top-link" (click)="openHelp()"  matTooltip="Open Help Center" matTooltipShowDelay="500">
      <mat-icon fontSet="fa" fontIcon="fa-circle-question"></mat-icon>
    </div>
    <div *ngIf="( hubObs | async ) && isAdminOfHub()" class="top-link" (click)="openHubSettings()"  matTooltip="Hub Settings" matTooltipShowDelay="500">
      <mat-icon fontSet="fa" fontIcon="fa-gear"></mat-icon>
    </div>
    <div *ngIf="( hubObs | async ) &&  isAdminOfHub()" class="top-link" (click)="openAnalytics()"  matTooltip="Hub Analytics" matTooltipShowDelay="500">
      <mat-icon fontSet="fa" fontIcon="fa-chart-column"></mat-icon>
    </div>
    <mat-menu class="top-mat-menu" #appMenu="matMenu" yPosition="below" xPosition="after" [overlapTrigger]="false">
      <div mat-menu-item [routerLink]="['/account']" style="color:white">
        <mat-icon style="color:white">account_circle</mat-icon> Edit my Account
      </div>
      <mat-divider></mat-divider>
      <div class="hub-menu" mat-menu-item *ngFor="let hub of user.Hubs">
        <div class="gotohub" (click)="switchHub(hub)">
          <div *ngIf="!hub.logo || !hub.logo.path">{{hub.title}}</div>
          <img *ngIf="hub.logo && hub.logo.path" class="hub-icon" src="{{hub.logo.path}}" alt="{{hub.title}}">
        </div>
        <!-- <ng-container *ngIf="isAdminOfHub(hub.id)">
          <div class="settings-separator"></div>
          <div>
            <mat-icon class="mat-icon-right" (click)="editHub(hub)" style="color:white">settings</mat-icon>
          </div>
        </ng-container> -->
      </div>
      <mat-divider></mat-divider>
      <div mat-menu-item (click)="signOut()" style="color:white">
        <mat-icon style="color:white">exit_to_app</mat-icon> Logout
      </div>

    </mat-menu>

    <button mat-button [matMenuTriggerFor]="appMenu">
      <img *ngIf="user.photo" class="user-image" width="24px" height="24px" src={{user.photo}} alt="">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div>

</div>