import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';
import { NgxUploaderModule } from 'ngx-uploader';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';

import { FuseProgressBarModule } from '@fuse/components';

import { AppComponent } from '@app/app.component';
import { LayoutModule } from '@app/layout/layout.module';
import { WondaModule } from '@app/main/wonda/wonda.module';
import { LoginModule } from '@app/main/login/login.module';
import { SharedModule } from '@shared/shared.module';
import { UserMenuModule } from '@app/layout/components/user-menu/user-menu.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { KalturaClient } from 'kaltura-ngx-client';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CurrentHubService } from '@shared/services/currentHub.service';
import { HubModule } from '@app/main/hub/hub.module';
import { GlobalErrorHandler } from '@app/error-handler/global-error-handler';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import * as echarts from 'echarts/core';
import { NgxEchartsModule } from 'ngx-echarts';

import { TitleComponent, LegendComponent } from 'echarts/components';
import { RadarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { ProgramModule } from '@app/program/program.module';

echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);
export function importEcharts() {
  return import('echarts');
}

const appRoutes: Routes = [{
  path: '**',
  redirectTo: ''
}];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false }), // set true to debug router

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // Fuse modules
    FuseProgressBarModule,

    // Firebase modules
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,

    NgxUploaderModule,
    ContextMenuModule,

    //Google analytics
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsFlowId, [{ command: 'set', values: ['user_properties', { editor_user: 'true' }] }]),
    NgxGoogleAnalyticsRouterModule,

    // App modules
    LayoutModule,
    LoginModule,
    HubModule,
    WondaModule,
    ProgramModule,
    SharedModule,
    UserMenuModule,

    // Echarts
    NgxEchartsModule.forRoot({ echarts: importEcharts }),
  ],
  providers: [
    CookieService,
    KalturaClient,
    AuthenticationService,
    CurrentHubService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ], // [{ provide: LOCALE_ID, useValue: 'fr' }], // for JIT
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
export class ProgressSpinnerConfigurableExample {
  color = 'primary';
  mode = 'determinate';
  value = 50;
}
